import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import germanLocale from '@angular/common/locales/de';
import {
  SocialLoginModule,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  GoogleSigninButtonModule,
} from '@abacritt/angularx-social-login';
import { registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthService, EnvironmentConfig, EnvironmentService, LibModule } from '@mapping-tool/lib';
import { environment } from '../environments/environment';
import { ChooseTenantPageComponent } from './pages/choose-tenant-page/choose-tenant-page.component';
import { ContactComponent } from './components/contact/contact.component';
import { CustomerLoginComponent } from './components/customer-login/customer-login.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { MappingPageComponent } from './pages/mapping-page/mapping-page.component';
import { SupportGoogleLoginComponent } from './components/support-google-login/support-google-login.component';
import { SupportMicrosoftLoginComponent } from './components/support-microsoft-login/support-microsoft-login.component';
import {
  MsalService,
  MsalGuard,
  MsalBroadcastService,
  MsalRedirectComponent,
  MsalGuardConfiguration,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
} from '@azure/msal-angular';
import {
  PublicClientApplication,
  InteractionType,
  IPublicClientApplication,
} from '@azure/msal-browser';
import { msalConfig } from './ms-auth-config';

registerLocaleData(germanLocale);
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ChooseTenantPageComponent,
    ContactComponent,
    CustomerLoginComponent,
    HeaderComponent,
    LoginPageComponent,
    MappingPageComponent,
    SupportGoogleLoginComponent,
    SupportMicrosoftLoginComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LibModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    SocialLoginModule,
    GoogleSigninButtonModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: 'AuthServiceInterface', useExisting: AuthService },
    EnvironmentService,
    {
      provide: EnvironmentConfig,
      useValue: environment,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '87931032901-vs8ucaee7ljmbq4p8tlihjpub5ladej1.apps.googleusercontent.com'
            ),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
