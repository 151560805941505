import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const msalConfig: Configuration = {
    auth: {
        clientId: '187f8b59-3653-4d36-befc-2b115d3be648',
        authority: 'https://login.microsoftonline.com/e71990c1-0ae9-461c-a979-7e61a1165bca',
        redirectUri: '/',
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: isIE
    },
    system: {
        loggerOptions: {
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false
        }
    }
}
