import { MappingDataService } from '../services/mapping.data.service';
import { FieldDefinition } from '../models/api/fieldDefinition';
import { ItemMapping } from '../models/api/itemMapping';
import { Mapping } from '../models/api/mappingModel';
import { MappingAggregatesEnum } from '../models/api/mappingAggregatesEnum';
import { MappingTypeEnum } from '../models/api/mappingTypeEnum';
import { UiStates } from '../models/ui-states';
import { MappingUiLogic } from './mapping-ui-logic';
import { SourceField } from '../models/api/sourceField';
import { MappingSource } from '../models/api/mappingSource';
import { LoggingDataService } from '../services/logging.data.service';
import { LogAction } from '../models/log-action';
import { Logger } from './mapping-logger';

export class MappingDataProcess {
  static fields: FieldDefinition[];

  static async SaveMappings(
    token: string,
    states: UiStates[],
    fields: FieldDefinition[],
    service: MappingDataService,
    logging: LoggingDataService,
  ): Promise<boolean> {
    this.fields = fields;
    const mappings = this.generateMappingsFromUiStates(states, fields);
    try {
      const result = await service.putMappings(mappings, token);
      if (!result) {
        return false;
      }
      await Logger.log(LogAction.Save, token, logging);
    } catch {
      return false;
    }
    return true;
  }

  static generateMappingsFromUiStates(
    states: UiStates[],
    fields: FieldDefinition[],
  ): Mapping[] {
    const ret = [] as Mapping[];
    if (states?.length == 0) return ret;

    states.forEach((s) => {
      if (!MappingUiLogic.IsFirstRecordKeySet(s)) return;

      const items: ItemMapping[] = [];
      let field2: SourceField = {
        name: '',
        items: [],
      };

      if (!s.recordKey.startsWith('spaces.')) {
        const index = fields.findIndex((f) => f.recordKey == s.assignedRecordKey1);
        if (index < 0) return;
        if (fields[index].aggregates.length == 0) return;

        items.push(... this.getFirstItemMapping(s));
        field2 = this.getSecondSourceField(s);
      }

      const source = {
        product: 'estatePro',
        fields: [
          {
            name: this.getSourceFieldName(s),
            items: items,
          },
          field2,
        ],
      };

      const aggregate = this.getAggregate(s);
      ret.push({ ... this.getMapping(aggregate, source, s.recordKey) });
    });

    return ret;
  }

  static getMapping(
    aggregate: MappingAggregatesEnum,
    source: MappingSource,
    targetField: string,
  ): Mapping {
    return {
      aggregate: aggregate,
      source: source,
      targetField: this.removeAggregatePrefix(targetField),
      type: MappingTypeEnum.SimpleFieldMapping,
      defaultValue: '',
    } as Mapping;
  }

  static removeAggregatePrefix(key: string): string {
    if (key.startsWith(MappingAggregatesEnum.Company + '_')) return key.substring(2);
    if (key.startsWith(MappingAggregatesEnum.Person + '_')) return key.substring(2);

    return key;
  }

  static getSourceFieldName(state: UiStates): string {
    if (state.recordKey.startsWith('spaces.'))
      return state.assignedRecordKey1;

    const index = this.fields.findIndex(
      (f) => f.recordKey == state.assignedRecordKey1,
    );
    if (index < 0) {
      return '';
    }
    return this.fields[index].recordKey;
  }

  static getAggregate(state: UiStates): MappingAggregatesEnum {
    if (state.recordKey.startsWith('spaces.'))
      return MappingAggregatesEnum.Estate;

    const index = this.fields.findIndex(
      (f) => f.recordKey == state.assignedRecordKey1,
    );
    switch (index) {
      case MappingAggregatesEnum.Person:
        return MappingAggregatesEnum.Person;
        case MappingAggregatesEnum.Company:
          return MappingAggregatesEnum.Company;
      default:
        return MappingAggregatesEnum.Estate;
    }
  }

  static getFirstItemMapping(state: UiStates): ItemMapping[] {
    const ret = [] as ItemMapping[];
    state.assignedMasterData1?.forEach((a) => {
      if (a.sourceId == -1) return;
      ret.push({
        source: a.sourceId.toString(),
        target: a.targetId.toString(),
      });
    });
    return ret;
  }

  static getSecondSourceField(state: UiStates): SourceField {
    const ret = {} as SourceField;
    if (state.assignedRecordKey2) {
      const index2 = this.fields.findIndex(
        (f) => f.recordKey == state.assignedRecordKey2,
      );
      if (index2 > -1) {
        ret.name = this.fields[index2].recordKey;
      }
    }
    return ret;
  }
}
