export class HttpClientGateway {
  async get(host: string, path: string, authorizationHeader: string) {
    const url = host + path;
    const requestInit: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: authorizationHeader,
        'Content-Type': 'appliation/json',
        mode: 'cors',
      },
      credentials: 'include',
    };

    return this.fetch(url, requestInit);
  }

  async patch(host: string, path: string, body: string, authorizationHeader: string) {
    const url = host + path;
    const requestInit: RequestInit = {
      method: 'PATCH',
      headers: {
        Authorization: authorizationHeader,
        'Content-Type': 'application/merge-patch+json',
      },
      body: body,
    };

    return this.fetch(url, requestInit);
  }

  async post(host: string, path: string, body: string, authorizationHeader: string) {
    return this.internalPost(host, path, body, 'application/json', authorizationHeader);
  }
  
  async postAsForm(host: string, path: string, body: string, authorizationHeader: string) {
    return this.internalPost(host, path, body, 'application/x-www-form-urlencoded', authorizationHeader);
  }

  private async internalPost(host: string, path: string, body: string, contentType: string, authorizationHeader: string) {
    const url = host + path;
    const requestInit: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: authorizationHeader,
        'Content-Type': contentType,
      },
      body: body,
    };

    return this.fetch(url, requestInit);
  }

  private async fetch(url: string, requestInit: RequestInit) {
    const response = await fetch(url, requestInit);
    const responseJson = await response.json();
    
    return {
      statusCode: response.status,
      statusMessage: response.statusText,
      headers: response.headers,
      body: JSON.stringify(responseJson),
    };
  }
}

