export const environment = {
  production: true,
  languageUrl: 'https://mapping.dev.estatepro.de/assets/i18n',
  baseUrl: 'https://api.migration.dev.empro.ai',
  migrationApiUrl: 'https://api.migration.dev.empro.ai',
  mappingApiUrl: 'https://api.migration-mapping.dev.empro.ai',
  mappingUrl: 'https://mapping.dev.estatepro.de',
  managementServiceUrl: 'https://management.dev.estatepro.de/',
  cognitoServiceUrl:
    'https://main-estate-dev.auth.eu-central-1.amazoncognito.com/',
  authenticationConfigUrl:
    'https://main-estatecloud-authentication.estate-dev.immocloud.io',
  supportLegitimateEmails: [
    'andrea.burghardt@immowelt.de',
    'frank.mueller@immowelt.de',
    'stefan.dietz@immowelt.de',
    'sdietz@immoweltag.de',
    'michael.fallmann@immowelt.de',
    'martin.tauber@immowelt.de',
    'vladislav.ackermann@immowelt.de',
    'artur.keller@immowelt.de',
    'aburghardt@immoweltag.de',
    'mueller@immoweltag.de',
    'mfallmann@hh.immoweltag.de',
    'm.tauber@immowelt.de',
    'vackermann@immoweltag.de',
    'akeller@immoweltag.de',
  ],
  stage: 'dev',
};
