import { FieldDefinition } from '../models/api/fieldDefinition';
import { FieldDefinitionFieldTypeEnum } from '../models/api/fieldDefinitionFieldTypeEnum';
import { FieldTypeRule } from './field-type-rule';
import { FieldTypeRuleWarningDescriptions, FieldTypeRuleWarningInfo } from '../models/fieldTypeRuleWarningInfo';
import { MappableFieldDefinition } from '../models/api/mappableFieldDefinition';
import { TranslateService } from '@ngx-translate/core';
import { UiStates } from '../models/ui-states';

export class FieldTypeRuleWarning {
  static translate: TranslateService;
  static fields: FieldDefinition[];
  static mappableFields: MappableFieldDefinition[];

  static GetWarnings(
    uiStates: UiStates[],
    fields: FieldDefinition[],
    mappableFields: MappableFieldDefinition[],
    translate: TranslateService,
  ): FieldTypeRuleWarningInfo {
    this.fields = fields;
    this.mappableFields = mappableFields;
    this.translate = translate;
    const ret = {
      hasWarning: false,
      header: translate.instant('Warning.fieldTypeRuleWarningHeader'),
      descriptions: [],
    } as FieldTypeRuleWarningInfo;

    uiStates.forEach((ui) => {
      if (!ui.assignedRecordKey1) return;
      if (ui.recordKey.startsWith('spaces.')) return;
      const targetIndex = mappableFields.findIndex(
        (m) => m.recordKey == ui.recordKey,
      );
      if (targetIndex === -1) return;

      ret.descriptions.push(
        ...this.getDescriptions(
          fields.findIndex((f) => f.recordKey == ui.assignedRecordKey1),
          targetIndex,
        ),
      );
      ret.descriptions.push(
        ...this.getDescriptions(
          fields.findIndex((f) => f.recordKey == ui.assignedRecordKey2),
          targetIndex,
        ),
      );
    });

    if (ret.descriptions.length > 0) ret.hasWarning = true;
    return ret;
  }

  static getDescriptions(sourceIndex: number, targetIndex: number): FieldTypeRuleWarningDescriptions[] {
    const descriptions: FieldTypeRuleWarningDescriptions[] = [];
    if (sourceIndex === -1) return descriptions;
    if (!this.fields[sourceIndex] || !this.mappableFields[targetIndex]) return descriptions;
    if (!this.fields[sourceIndex].fieldType || !this.mappableFields[targetIndex].fieldType) return descriptions;    

    const allowed = FieldTypeRule.isMappingAllowed(
      this.fields[sourceIndex].fieldType,
      this.mappableFields[targetIndex].fieldType,
    );
    const warning = this.getWarning(
      this.fields[sourceIndex].fieldType.toString(), 
      this.mappableFields[targetIndex].fieldType.toString());

    if (allowed && warning) {
      descriptions.push({description:
        this.translate.instant('Warning.fieldTypeRuleWarningDescription', {
          value1: this.translate.instant('Glosar.' + this.mappableFields[targetIndex].targetEntityType.toString()),
          value2: (this.mappableFields[targetIndex].groupName ?  '-' + this.mappableFields[targetIndex].groupName : ''),
          value3: this.fields[sourceIndex].name,          
          value4: this.mappableFields[targetIndex].name,
          value5: this.translate.instant('ControlType.' + this.fields[sourceIndex].fieldType),
          value6: this.translate.instant('ControlType.' + this.mappableFields[targetIndex].fieldType)
        }), elementId: this.mappableFields[targetIndex].recordKey} as FieldTypeRuleWarningDescriptions);
    }
    return descriptions;
  }

  static getWarning(
    sourceType: string,
    targetType: string,
  ): boolean {
    if (sourceType === targetType) return false;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Text])
      return false;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Memo])
      return false;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.FreeTextCombo])
      return false;

    switch (sourceType) {
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Text]:
        return this.isMappingCriticalForText();
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Numeric]:
        return this.isMappingCriticalForNumeric(targetType);
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Date]:
        return this.isMappingCriticalForDate();
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Combo]:
        return this.isMappingCriticalForCombo(targetType);
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.List]:
        return this.isMappingCriticalForList(targetType);
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Option]:
        return this.isMappingCriticalForOption();
      case FieldDefinitionFieldTypeEnum[
        FieldDefinitionFieldTypeEnum.NumericFromTill
      ]:
        return this.isMappingCriticalForNumericFromTill(targetType);
      case FieldDefinitionFieldTypeEnum[
        FieldDefinitionFieldTypeEnum.DateFromTill
      ]:
        return this.isMappingCriticalForDateFromTill(targetType);
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Memo]:
        return this.isMappingCriticalForMemo();
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Check]:
        return this.isMappingCriticalForCheck(targetType);
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Currency]:
        return this.isMappingCriticalForCurrency(targetType);
      case FieldDefinitionFieldTypeEnum[
        FieldDefinitionFieldTypeEnum.CurrencyFromTill
      ]:
        return this.isMappingCriticalForCurrencyFromTill(targetType);
      case FieldDefinitionFieldTypeEnum[
        FieldDefinitionFieldTypeEnum.HierarchicalList
      ]:
        return this.isMappingCriticalForHierarchicalList(targetType);
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.AreaCode]:
        return this.isMappingCriticalForAreaCode();
      case FieldDefinitionFieldTypeEnum[
        FieldDefinitionFieldTypeEnum.WholeNumber
      ]:
        return this.isMappingCriticalForWholeNumber(targetType);
      case FieldDefinitionFieldTypeEnum[
        FieldDefinitionFieldTypeEnum.WholeNumberFromTill
      ]:
        return this.isMappingCriticalForWholeNumberFromTill(targetType);
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.MonthYear]:
        return this.isMappingCriticalForMonthYear();
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Fraction]:
        return this.isMappingCriticalForFraction();
      case FieldDefinitionFieldTypeEnum[
        FieldDefinitionFieldTypeEnum.AreaCodeList
      ]:
        return this.isMappingCriticalForAreaCodeList();
      case FieldDefinitionFieldTypeEnum[
        FieldDefinitionFieldTypeEnum.FreeTextCombo
      ]:
        return this.isMappingCriticalForFreeTextCombo();
    }
    return false;
  }

  static isMappingCriticalForText() {
    return true;
  }

  static isMappingCriticalForNumeric(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.WholeNumber])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.WholeNumberFromTill])
      return true;
    return false;
  }

  static isMappingCriticalForDate() {
    return false;
  }

  static isMappingCriticalForCombo(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Numeric])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Option])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.WholeNumber])
      return true;
    return false;
  }

  static isMappingCriticalForList(targetType: string) {
    if (
      targetType ===
      FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Combo]
    )
      return true;
    return false;
  }

  static isMappingCriticalForOption() {
    return false;
  }

  static isMappingCriticalForNumericFromTill(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Numeric])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.WholeNumber])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.WholeNumberFromTill])
      return true;
    return false;
  }

  static isMappingCriticalForDateFromTill(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Date])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.MonthYear])
      return true;
    return false;
  }

  static isMappingCriticalForMemo() {
    return true;
  }

  static isMappingCriticalForCheck(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.MonthYear])
      return true;
    return false;
  }

  static isMappingCriticalForCurrency(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.NumericFromTill])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.CurrencyFromTill])
      return true;
    return false;
  }

  static isMappingCriticalForCurrencyFromTill(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Currency])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.WholeNumber])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.WholeNumberFromTill])
      return true;
    return false;
  }

  static isMappingCriticalForHierarchicalList(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Combo])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Option])
      return true;
    return false;
  }

  static isMappingCriticalForAreaCode() {
    return false;
  }

  static isMappingCriticalForWholeNumber(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.NumericFromTill])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.CurrencyFromTill])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.WholeNumberFromTill])
      return true;
    return false;
  }

  static isMappingCriticalForWholeNumberFromTill(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Numeric])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Currency])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.WholeNumber])
      return true;
    return false;
  }

  static isMappingCriticalForMonthYear() {
    return false;
  }

  static isMappingCriticalForFraction() {
    return false;
  }

  static isMappingCriticalForAreaCodeList() {
    return false;
  }

  static isMappingCriticalForFreeTextCombo() {
    return false;
  }
}
