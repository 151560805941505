import { ViewportScroller } from '@angular/common';
import { Component, NgZone, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import {
  AutoPreset,
  AssignedMasterData,
  FieldTypeRuleWarning,
  FieldTypeRuleWarningInfo,
  LoggingDataService,
  LoginService,
  MappableFieldDefinition,
  MappingDataProcess,
  MappingDataProvisioning,
  MappingDataService,
  MappingUiLogic,
  MigrationDataProcess,
  MigrationDataService,
  SharedParameterService,
  Translation,
  UiStates,
  FieldDefinition,
  Mapping,
  MasterDataListItem,
  MappingAggregatesEnum,
} from '@mapping-tool/lib';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mapping-tool-mapping-page',
  templateUrl: './mapping-page.component.html',
  styleUrls: ['./mapping-page.component.scss'],
})
export class MappingPageComponent implements OnInit {
  form: FormGroup = new FormGroup({});
  mappableFields: MappableFieldDefinition[] = [];
  fieldDefinitions: FieldDefinition[] = [];
  fieldDefinitionClone: FieldDefinition[] = [];
  mappings: Mapping[] = [];
  aggregates: string[] = [];
  token = '';
  supportToken = '';
  dataLoadError = false;
  dataSaveError = false;
  dataRefeedError = false;
  dataSaveSuccess = false;
  dataRefeedSuccess = false;
  isLoading = false;
  isSaving = false;
  fieldTypeRuleWarningInfo: FieldTypeRuleWarningInfo = {} as FieldTypeRuleWarningInfo;
  successInfoDelay = 5000;
  selectedProject = '';
  uiStates: UiStates[] = [];
  selectedCountry = '';
  closeWarningBox = false;

  constructor(
    private ngZone: NgZone,    
    private router: Router,
    private mappingService: MappingDataService,
    private migrationService: MigrationDataService,
    private translate: TranslateService,
    private viewportScroller: ViewportScroller,
    public parameterService: SharedParameterService,
    private loginService: LoginService,
    private logger: LoggingDataService,
  ) {
    this.translate = Translation.initTranslateService(this.translate);
  }

  async ngOnInit() {
    await this.loadDataOnce();
  }

  async loadDataOnce() {
    this.isLoading = true;
    this.token = await this.loginService.getToken(this.parameterService?.token)??'';

    if (!this.token||this.token.length == 0) {
      this.redirectToLoginPage();
      this.isLoading = false;
      return;
    }

    await Promise.all([
      this.fetchMappableFields(),
      this.fetchMappings(),
      this.fetchFieldDefinitionsMasterData()
    ]);

    this.prepareFieldDefinitions();
    this.prepareMappables();
    this.getDistinctEntityTypes();
    this.initUiStates();
    this.prepareUiWithMappings();
    this.prepareUiWithMasterData();
    this.showFieldTypeWarning();

    this.isLoading = false;
  }

  async loadDataForRepeating() {
    this.isLoading = true;
    await this.fetchMappings();
    this.initUiStates();
    this.prepareUiWithMappings();
    this.prepareUiWithMasterData();
    this.showFieldTypeWarning();
    this.isLoading = false;
  }

  async fetchMappableFields() {
    this.dataLoadError = false;
    await this.mappingService
      .getMappableFieldDefinitions(this.token)
      .then((x) => {
        this.mappableFields = x;
      })
      .catch(() => {
        this.dataLoadError = true;
      });
    if (!this.mappableFields || this.mappableFields.length == 0)
      this.dataLoadError = true;

    MappingUiLogic.EnrichMappables(this.mappableFields);
  }

  async fetchMappings() {
    this.dataLoadError = false;
    await this.mappingService
      .getMappings(this.token)
      .then((x) => {
        this.mappings = x;
      })
      .catch((error) => {console.log(error); this.dataLoadError = true; });
  }

  async fetchFieldDefinitionsMasterData() {
    await this.mappingService
      .getFieldDefinitionsMasterdata(this.token)
      .then((x) => {
        this.fieldDefinitions = x;
      })
      .catch((reason: Error) => {
        console.log(reason);
        this.dataLoadError = true;
      });
  }

  initUiStates() {
    this.uiStates = [];
    this.mappableFields.forEach((x) => {
      const states: UiStates = {
        recordKey: x.recordKey,
        assignedRecordKey1: !x.fixedSourceField ? '' : x.fixedSourceField,
        assignedRecordKey2: '',
        hasMasterData: x.masterDataList.length > 0,
        hasMasterDataHierarchical: x.masterDataHierarchicalList.length > 0,
        isAnotherFieldDefinitionButtonClicked: false,
        initFieldDefinition: {} as FieldDefinition,
        initFieldDefinition2: {} as FieldDefinition,
        initMapping: {} as Mapping,
        masterData: {} as MasterDataListItem[],
        masterDataHierarchical: {} as MasterDataListItem[],
        assignedMasterData1: [] as AssignedMasterData[],
        assignedMasterData2: [] as AssignedMasterData[]
      };
      this.uiStates.push(states);
    });
    this.closeWarningBox = false;
  }

  prepareFieldDefinitions() {
    this.fieldDefinitionClone = MappingDataProvisioning.GetPrefixedData(
      this.fieldDefinitions
    );
  }

  prepareMappables() {
    this.mappableFields.forEach((x) => {
      x.isReadOnly = !x.fixedSourceField ? '' : 'disabled';
    });
  }

  prepareUiWithMappings() {
    if (!this.mappings || this.mappings.length == 0) return;
    if (!this.mappableFields || this.mappableFields.length == 0) return;
    if (!this.fieldDefinitionClone || this.fieldDefinitionClone.length == 0) return;

    // mapping
    this.mappings.forEach((mapping) => {

      let targetField = mapping.targetField;
      if (mapping.aggregate.toString() === MappingAggregatesEnum[MappingAggregatesEnum.Company]
        || mapping.aggregate.toString() === MappingAggregatesEnum[MappingAggregatesEnum.Person])
        targetField = MappingAggregatesEnum[mapping.aggregate] + '_' + mapping.targetField;

      // mappables
      const mappableIndex = this.mappableFields.findIndex(
        (mf) => mf.recordKey == targetField
      );
      if (mappableIndex > -1) {
        const mappable = this.mappableFields[mappableIndex];

        // fieldDefinition
        let fieldDefinitionIndex = this.fieldDefinitionClone.findIndex(
          (fd) => fd.recordKey == mapping?.source?.fields[0]?.name
        );
        const fieldDefinitionIndex2 = this.fieldDefinitionClone.findIndex(
          (fd) => fd.recordKey == mapping?.source?.fields[1]?.name
        );

        // fieldDefinition spaces
        if (mappable.groupName == 'Flächen') {
          fieldDefinitionIndex = this.fieldDefinitionClone.findIndex(
            (fd) => fd.recordKey == 'SObjFlaechen'
          );
        }
        this.setInitialData(fieldDefinitionIndex, fieldDefinitionIndex2, mapping, mappable);
      }
    });
  }

  setInitialData(index: number, index2: number, mapping: Mapping, mappable: MappableFieldDefinition) {
    if (index > -1) {
      const fieldDefinition = this.fieldDefinitionClone[index];
      const stateIndex = this.uiStates.findIndex((x) => x.recordKey == mappable.recordKey);
      if (stateIndex == -1)
        return;
      this.uiStates[stateIndex].initFieldDefinition = fieldDefinition;
      this.uiStates[stateIndex].initMapping = mapping;
      this.uiStates[stateIndex].assignedRecordKey1 = fieldDefinition.recordKey;
      this.uiStates[stateIndex].assignedMasterData1 =
        MappingUiLogic.GetInitialAssignedMasterData(mapping.source?.fields[0], mappable);

      if (index2 > -1) {
        const fieldDefinition = this.fieldDefinitionClone[index2];
        this.uiStates[stateIndex].assignedRecordKey2 = fieldDefinition.recordKey;
        this.uiStates[stateIndex].initFieldDefinition2 = fieldDefinition;
      }

      if (fieldDefinition.recordKey === 'SObjFlaechen') {
        if (mapping.targetField === mappable.recordKey) {
          this.uiStates[stateIndex].assignedRecordKey1 = mapping.source.fields[0]?.name;
        }
      }
    }
  }

  prepareUiWithMasterData() {
    this.mappableFields.forEach((map) => {
      this.setMasterDataList(map.recordKey, map.fixedSourceField);
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFieldDefinitionMasterDataSelectionChanged(event: any) {
    const isSecondSelect = event.srcElement.id.includes('__2');
    const srcElm = event.srcElement.id.replace('m_', '').replace('__2', '');
    const state = this.uiStates.filter((x) => x.recordKey === srcElm)[0];
    const isAssignedToFieldDefinition = event.target.value.length > 0;

    if (!state) return;

    if (!isAssignedToFieldDefinition && !isSecondSelect)
      state.assignedRecordKey1 = '';
    if (!isAssignedToFieldDefinition && isSecondSelect)
      state.assignedRecordKey2 = '';
    if (isAssignedToFieldDefinition && !isSecondSelect)
      state.assignedRecordKey1 = event.target.value;
    if (isAssignedToFieldDefinition && isSecondSelect)
      state.assignedRecordKey2 = event.target.value;

    this.toggleAnotherFieldButton(event);
    this.toggleMasterDataListArea(state, event);
    this.showFieldTypeWarning();
  }

  showFieldTypeWarning() {
    this.fieldTypeRuleWarningInfo =
      FieldTypeRuleWarning.GetWarnings(this.uiStates, this.fieldDefinitionClone, this.mappableFields, this.translate);

    if (this.fieldTypeRuleWarningInfo.hasWarning)
      this.closeWarningBox = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSpaceSelectionChanged(event: any) {
    const srcElm = event.srcElement.id.replace('ms_', '');
    const state = this.uiStates.filter((x) => x.recordKey === srcElm)[0];
    if (!state) return;

    const isAssignedToFieldDefinition = event.target.value.length > 0;
    if (!isAssignedToFieldDefinition) {
      state.assignedRecordKey1 = '';
      return;
    }

    state.assignedRecordKey1 = event.target.value;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMasterDataSelectionChanged(event: any, mappable: MappableFieldDefinition) {
    const targetId = event.srcElement.id.replace('md_', '');
    if (!targetId) return;

    const state = this.uiStates.filter((x) => x.recordKey === mappable.recordKey)[0];
    if (state?.masterData?.length == 0) return;

    const non = this.translate.instant('Mapping.noMasterdataChosen');
    if (event.target.value === non) {
      state.assignedMasterData1.filter(x => x.targetId === targetId)[0].sourceId = -1;
      return;
    }

    const isAssignedToFieldDefinition = event.target.value.length > 0;
    const masterData = state.masterData.filter(x => x.name === event.target.value);
    if (!masterData || masterData.length == 0) return;

    const id = state.masterData.filter(x => x.name === event.target.value)[0].id;
    if (!id) return;

    if (!isAssignedToFieldDefinition)
      state.assignedMasterData1.filter(x => x.targetId === targetId)[0].sourceId = -1;
    else
      state.assignedMasterData1.filter(x => x.targetId === targetId)[0].sourceId = id;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleAnotherFieldButton(event: any) {
    const srcElm = event.srcElement.id.replace('m_', '').replace('__2', '');
    const btn = document.getElementById('b_' + srcElm);
    const isAssignedToFieldDefinition = event.target.value.length > 0;

    if (btn) {
      if (!isAssignedToFieldDefinition) {
        // noch kein Feld ausgewählt - Button weiteres Quellfeld
        (<HTMLInputElement>btn).disabled = true;
        (<HTMLInputElement>btn).style.display = 'none';
        this.uiStates[srcElm].masterData = [];
        MappingUiLogic.SetAssignedMasterDataTarget(this.uiStates[srcElm], this.mappableFields);
      } else {
        (<HTMLInputElement>btn).disabled = false;
        (<HTMLInputElement>btn).style.display = 'flex';
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleMasterDataListArea(states: UiStates, event: any) {
    const srcElm = event.srcElement.id.replace('m_', '').replace('__2', '');
    const isAssignedToFieldDefinition = event.target.value.length > 0;

    if (states.hasMasterData || states.hasMasterDataHierarchical) {
      if (!isAssignedToFieldDefinition) {
        const elm = document.getElementById('mdl_' + srcElm);
        if (elm) (<HTMLInputElement>elm).style.display = 'none';
      } else {
        this.setMasterDataList(event.target.value);
      }
    }
  }

  setMasterDataList(recordKey: string, fixedSourceField?: string) {
    if (!this.fieldDefinitionClone) return;

    const fields = this.fieldDefinitionClone.filter((x) => x.recordKey === (!fixedSourceField ? recordKey : fixedSourceField));
    if (!fields || fields.length == 0) return;
    const states = this.uiStates.filter((x) => x.recordKey === recordKey);
    if (!states || states.length == 0) return;
    states[0].masterData = fields[0].masterDataList;
  }

  setMasterDataListFromSelectedFieldDefinition(recordKeySource: string, recordKeyTarget: string) {
    if (!this.fieldDefinitionClone) return;

    const fields = this.fieldDefinitionClone.filter((x) => x.recordKey === recordKeySource);
    if (!fields || fields.length == 0) return;

    const states = this.uiStates.filter((x) => x.recordKey === recordKeyTarget);
    if (!states || states.length == 0) return;
    states[0].masterData = fields[0].masterDataList;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickAnotherFieldDefinition(event: any) {
    const elId = event.srcElement.id.replace('b_', '');

    const states = this.uiStates.filter((x) => x.recordKey === elId);
    if (states && states.length > 0) {
      states[0].isAnotherFieldDefinitionButtonClicked = true;
    }
  }

  onClickWarningToControl(elementId: string) {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  onClickCloseWarningBox() {
    this.closeWarningBox = true;
  }

  showAnotherFieldDefinition(def: MappableFieldDefinition) {
    if (def.fixedSourceField?.length > 0) return false;

    const states = this.uiStates.filter((x) => x.recordKey === def.recordKey);
    if (!states || states.length == 0) return false;

    if (states[0].isAnotherFieldDefinitionButtonClicked) return true;

    return states[0].assignedRecordKey2?.length > 0;
  }

  showAnotherFieldDefinitionButton(mapp: MappableFieldDefinition) {
    if (mapp.fixedSourceField?.length > 0) return false;
    if (mapp.groupName === 'Flächen') return false;

    const states = this.uiStates.filter((x) => x.recordKey === mapp.recordKey);
    if (!states || states.length == 0) return false;

    return !states[0].isAnotherFieldDefinitionButtonClicked;
  }

  showFirstMasterDataList(mbl: MappableFieldDefinition) {
    const states = this.uiStates.filter((x) => x.recordKey === mbl.recordKey);
    if (states?.length == 0) return false;

    if (!MappingUiLogic.IsFirstRecordKeySet(states[0])) return false;

    if (!states[0].hasMasterData && !states[0].hasMasterDataHierarchical)
      return false;

    return true;
  }

  showSecondMasterDataList(def: MappableFieldDefinition) {
    if (def.fixedSourceField?.length > 0) return false;

    const states = this.uiStates.filter((x) => x.recordKey === def.recordKey);
    if (!states || states.length == 0) return false;

    if (!states[0].hasMasterData && !states[0].hasMasterDataHierarchical)
      return false;
    return states[0].isAnotherFieldDefinitionButtonClicked;
  }

  showIfIsSpace(mapp: MappableFieldDefinition) {
    if (!mapp) return false;
    return mapp.groupName === 'Flächen';
  }

  sortByAggregates() {
    if (!this.aggregates) return null;
    return this.aggregates.sort((a, b) => (a > b ? 0 : -1));
  }

  sortByGroupNameDistinct(aggregate: string) {
    const aggFiltered = this.mappableFields.filter(
      (x) => x.targetEntityType.toString() == aggregate
    );
    return aggFiltered.filter(
      (thing, i, arr) =>
        arr.findIndex((t) => t.groupName === thing.groupName) === i
    );
  }

  sortMasterDataByName(list: MasterDataListItem[]) {
    return list.sort((a, b) => a.name.localeCompare(b.name));
  }

  filterByAggregatesortByMappableFields(
    sortProp: string,
    filterProp: string,
    groupName: string
  ) {
    return this.mappableFields
      .filter(
        (x) =>
          x.targetEntityType.toString() == filterProp &&
          x.groupName.toString() == groupName
      )
      .sort(
        (a, b) =>
        a.targetEntityType.toString().localeCompare(b.targetEntityType.toString()) || a.name.localeCompare(b.name)
      );
  }

  getDistinctEntityTypes() {
    this.aggregates = [
      ...new Set(
        this.mappableFields.map((item) => item.targetEntityType.toString())
      ),
    ];
  }

  getMasterDataListFromSelectedField(recordKey: string) {
    if (!this.fieldDefinitionClone || this.fieldDefinitionClone.length == 0) return;
    const dropdown = document.getElementById('m_' + recordKey);
    const selectedOption = (dropdown as HTMLSelectElement)?.value;

    const fields = this.fieldDefinitionClone.filter((x) => x.recordKey === selectedOption);
    if (!fields || fields.length == 0) return;
    this.setMasterDataListFromSelectedFieldDefinition(selectedOption, recordKey);

    const states = this.uiStates.filter((x) => x.recordKey === recordKey);
    if (!states || states.length == 0 || !states[0].masterData || states[0].masterData.length === undefined)
      return;
    return states[0].masterData.sort((a, b) => a.name.localeCompare(b.name));
  }

  getFieldDefinitionFilterByAggregateSorted(aggregate: string, mappable: MappableFieldDefinition): FieldDefinition[] {
    return MappingUiLogic.GetFieldDefinitionFilterByAggregateSorted(
      this.fieldDefinitionClone, aggregate, mappable
    );
  }

  getFieldDefinitionForSpacesSorted(def: MappableFieldDefinition) {
    return MappingUiLogic.GetFieldDefinitionForSpacesSorted(
      this.fieldDefinitionClone,
      def
    );
  }

  getUiTargetNamePostfix(mapp: MappableFieldDefinition) {
    if (mapp.mappingAggregatesEnum === MappingAggregatesEnum.Person)
      return '(P)';
    if (mapp.mappingAggregatesEnum === MappingAggregatesEnum.Company)
      return '(F)';
    return '';
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  spaceIdentify(item: any) {
    return item.label;
  }

  isFirstFieldSelected(def: FieldDefinition, mapp: MappableFieldDefinition) {
    // fixedSourceField
    if (def.recordKey === mapp.fixedSourceField) return 'selected';

    // mappings
    const stateIndex = this.uiStates.findIndex((x) => x.recordKey == mapp.recordKey);

    if (stateIndex == -1) return '';
    if (!this.uiStates[stateIndex].initFieldDefinition?.recordKey) return '';
    if (!this.uiStates[stateIndex].initMapping?.targetField) return '';

    if (def.recordKey == this.uiStates[stateIndex].initFieldDefinition?.recordKey)
      return 'selected';

    return '';
  }

  isSecondFieldSelected(def: FieldDefinition, mapp: MappableFieldDefinition) {
    const stateIndex = this.uiStates.findIndex((x) => x.recordKey == mapp.recordKey);
    if (stateIndex == -1) return '';

    if (!this.uiStates[stateIndex].initFieldDefinition2?.recordKey) return '';
    if (!this.uiStates[stateIndex].initMapping?.targetField) return '';

    if (def.recordKey == this.uiStates[stateIndex].initFieldDefinition2?.recordKey)
      return 'selected';

    return '';
  }

  isSpaceFieldSelected(def: FieldDefinition, mapp: MappableFieldDefinition) {
    if (def.groupName != 'Flächen') return '';
    if (def.recordKey === mapp.fixedSourceField) return 'selected';

    const state = this.uiStates.filter((x) => x.recordKey == mapp.recordKey)[0];
    if (!state) return '';

    // auto preset
    if (def.recordKey === state.assignedRecordKey1) return 'selected';
    if (state.initFieldDefinition?.recordKey !== 'SObjFlaechen') return '';

    // init mapping
    if (!state.initMapping?.targetField) return '';

    if (def.recordKey === state.initMapping.source?.fields[0]?.name
      && mapp.recordKey === state.initMapping.targetField)
      return 'selected';

    return '';
  }

  isFirstMasterDataSelected(masterDataSource: MasterDataListItem, masterDataTarget: MasterDataListItem, mappable: MappableFieldDefinition) {
    if (mappable.masterDataList?.length == 0 && mappable.masterDataHierarchicalList?.length == 0)
      return '';

    const stateIndex = this.uiStates.findIndex((x) => x.recordKey == mappable.recordKey);
    if (stateIndex == -1)
      return '';
    const state = this.uiStates[stateIndex];

    if (!state.initMapping?.source?.fields)
      return '';

    for (const i of state.initMapping.source.fields[0].items) {
      if (i.target == masterDataTarget.id.toString() && i.source == masterDataSource.id.toString()) {
        return 'selected';
      }
    }
    return '';
  }

  btnAuto() {
    AutoPreset.DoPreset(this.uiStates, this.mappableFields, this.fieldDefinitionClone);
  }

  async btnReset() {
    await this.loadDataForRepeating();
  }

  btnDismissSaveError() {
    this.dataSaveError = false;
  }

  async btnSave() {
    this.isSaving = true;
    try {
      this.token = await this.loginService.getRefreshToken(this.parameterService)??'';
      if (!this.token||this.token.length == 0) {
        this.isSaving = false;
        this.redirectToLoginPage();
      }

      const result = await MappingDataProcess.SaveMappings(this.token, this.uiStates, this.fieldDefinitionClone, this.mappingService, this.logger);
      if (!result) {
        this.dataSaveError = true;
      }
      else {
        this.dataSaveSuccess = true;
        setTimeout(() => this.dataSaveSuccess = false, this.successInfoDelay);
      }
    } catch (error) {
      this.dataSaveError = true;
    }
    this.isSaving = false;
  }

  btnDismissRefeedError() {
    this.dataRefeedError = false;
  }

  async btnRefeed() {
    try {
      const aggregatesToRefeed = this.detectAggregatesChanged();
      const result = await MigrationDataProcess.RefeedAggregates(this.token, this.migrationService, aggregatesToRefeed, this.logger);
      if (!result) {
        this.dataRefeedError = true;
      }
      else {
        this.dataRefeedSuccess = true;
        setTimeout(() => this.dataRefeedSuccess = false, this.successInfoDelay);
      }
    } catch (error) {
      this.dataRefeedError = true;
    }
  }

  chkPersonsChecked = false;
  chkCompaniesChecked = false;
  chkEstatesChecked = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chkBoxClick(event: any) {
    switch (event?.srcElement?.id) {
      case "personsCheck":
        this.chkPersonsChecked = event.target.checked
        break;
      case "companiesCheck":
        this.chkCompaniesChecked = event.target.checked
        break;
      case "estatesCheck":
        this.chkEstatesChecked = event.target.checked
        break;
      default:
        break;
    }
  }

  detectAggregatesChanged(): MappingAggregatesEnum[] {
    const aggregates: MappingAggregatesEnum[] = [];

    if (this.chkPersonsChecked)
      aggregates.push(MappingAggregatesEnum.Person);
    if (this.chkCompaniesChecked)
      aggregates.push(MappingAggregatesEnum.Company);
    if (this.chkEstatesChecked)
      aggregates.push(MappingAggregatesEnum.Estate);

    return aggregates;
  }

  redirectToLoginPage() {
    this.ngZone.run(() => {
      void this.router.navigate(['']);
    });
  }
}
