<div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="font-sans title mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">{{ 'Login.signIn' | translate }}</h2>
    </div>
    <div class="mt-6 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">
        <form class="space-y-6" action="#" method="POST" [formGroup]="form" (ngSubmit)="onSubmit()" name="form">
          <div>
            <div>
                <label for="email" class="font-sans block text-base font-medium leading-6 text-gray-900">{{ 'Login.yourEmail' | translate }}</label>
                <div class="mt-2">                
                    <input id="email" name="email" type="email" formControlName="email" autocomplete="email" required class="font-sans block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6" />
                </div>
            </div>
          </div>
          <div>
            <div class="relative">
                <label class="font-sans block text-base font-medium leading-6 text-gray-900">{{ 'Login.password' | translate }}</label>
                <div class="right-inner-addon pull-left col-lg-4">
                    <input id="password" name="password" formControlName="password" [type]="isPasswordVisible ? 'text' : 'password'" required class="input-field font-sans block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6" >
                    <div (click)="togglePasswordVisibility()" type="button" class="input-button">
                        <svg height="20px" *ngIf="!isPasswordVisible" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"></path>
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                        </svg>
                        <svg height="20px" *ngIf="isPasswordVisible" fill="none" stroke="currentColor" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"></path>
                        </svg>
                    </div>   
                </div>
            </div>
          </div>
          <div>
            <button type="submit" class="font-sans flex w-full justify-center rounded-md bg-yellow-300 px-3 py-2 text-base font-semibold text-gray-700 shadow-sm hover:bg-yellow-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-yellow-300">{{ 'Login.signIn' | translate }}</button>
          </div>
        </form>
        <div class="mt-6 sm:mx-auto sm:w-full sm:max-w-md">
            <mapping-tool-support-google-login [form]="this.form"></mapping-tool-support-google-login>
        </div>
        <div class="mt-6 sm:mx-auto sm:w-full sm:max-w-md">
            <mapping-tool-support-microsoft-login></mapping-tool-support-microsoft-login>
        </div>        
      </div>
    </div>
</div>
