import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { EnvironmentConfig } from '../models/environment-config';
import { LoginModel } from '../models/login-model';
import { LoginRole } from '../models/login-role';
import { AuthService } from './auth.service';
import {
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser,
} from '@abacritt/angularx-social-login';
import { Logger } from '../business/mapping-logger';
import { LogAction } from '../models/log-action';
import { LoggingDataService } from './logging.data.service';
import { LocalStorageEnum } from './local-storage.service';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { SupportLoginService } from './support-login.service';
import { SharedParameterService } from './shared-parameter.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private googleAuthState: BehaviorSubject<SocialUser> =
    new BehaviorSubject<SocialUser>({} as SocialUser);
  config: EnvironmentConfig = {} as EnvironmentConfig;
  roleAs = '';
  
  constructor(
    private authService: AuthService,
    private socialAuthService: SocialAuthService,
    private loggingService: LoggingDataService,
    private microsoftAuthService: MsalService,
    private supportLoginService: SupportLoginService,
    private router: Router    
  ) {}

  async setCustomerData(data: LoginModel) {
    await this.authService.login(data.email, data.password);
    await this.authService.checkAuthenticated();
    this.setLocalStorageRole();
    localStorage.setItem('ROLE', LoginRole.Customer);
  }

  set setSupportGoogleData(val: SocialUser | undefined) {
    if (!val || !this.isEmailSupported(val.email)) return;
    this.googleAuthState.next(val);
    this.setLocalStorageRole(val.email);
  }

  set setSupportMicrosoftData(email: string) {
    if (!email || !this.isEmailSupported(email)) return;
    this.setLocalStorageRole(email);
  }

  async logout() {
    this.resetLocalStorage();

    if (await this.authService.checkAuthenticated()) {
      await this.authService.logout();
      return;
    }

    if (this.supportGoogleAuthState) {
      await this.socialAuthService.signOut();
      return;
    }

    if (this.supportMicrosoftAuthState) {
      await this.authService.logout();
      this.microsoftAuthService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    }
    this.resetLocalStorage();
    this.redirectToLoginPage();
  }

  async isValidAuthenticated(): Promise<boolean> {
    const isAuthenticated = await this.customerOrSupportIsAuthenticated();
    if (!isAuthenticated) return false;
    if (
      localStorage.getItem('ROLE') == LoginRole.Customer &&
      !localStorage.getItem('TENANT')
    )
      return false;
    if (
      localStorage.getItem('ROLE') != LoginRole.Support &&
      this.supportGoogleAuthState
    )
      return false;
    return true;
  }

  async customerOrSupportIsAuthenticated(): Promise<boolean> {
    const supportIsAuthenticated = this.supportAuthState;
    if (supportIsAuthenticated) return true;
    const customerIsAuthenticated = await this.authService.checkAuthenticated();
    if (customerIsAuthenticated) return true;
    return false;
  }

  get supportGoogleAuthState(): boolean {
    const googleAuthState = this.googleAuthState.asObservable();
    if (googleAuthState) {
      const googleUserMail = localStorage.getItem('USER');
      if (googleUserMail) return this.isEmailSupported(googleUserMail);
    }
    return false;
  }

  get supportMicrosoftAuthState(): boolean {
    const accounts = this.microsoftAuthService.instance?.getAllAccounts();
    if (accounts?.length > 0) {
      const microsoftUserMail = accounts[0].username;
      this.setSupportMicrosoftData = microsoftUserMail;
      return this.isEmailSupported(accounts[0].username);
    }
    return false;
  }

  get supportAuthState(): boolean {
    if (!this.config?.supportLegitimateEmails) return false;
    if (this.supportGoogleAuthState) return true;
    return this.supportMicrosoftAuthState;
  }

  isEmailSupported(email: string): boolean {
    if (!this.config?.supportLegitimateEmails) return false;
    return this.config?.supportLegitimateEmails.some(
      (x) => x === email.toLowerCase(),
    );
  }

  setLocalStorageRole(user?: string) {
    if (!user) {
      localStorage.setItem('ROLE', LoginRole.Customer);
      localStorage.setItem('USER', '');
    }
    else {
      localStorage.setItem('ROLE', LoginRole.Support);
      localStorage.setItem('USER', user);
    }
  }

  get getRole() {
    const role = localStorage.getItem('ROLE');
    if (role) this.roleAs = role;
    return this.roleAs;
  }

  set environmentConfig(config: EnvironmentConfig) {
    this.config = config;
  }

  async refreshSocialLoginToken(): Promise<void> {
    await this.socialAuthService.refreshAccessToken(GoogleLoginProvider.PROVIDER_ID);
  }

  async getToken(parameterToken: string) {
    let token;
    try {
      if (parameterToken) token = parameterToken;
      else if (localStorage.getItem(LocalStorageEnum.Tenant))
        token = (await this.supportLoginService.getAuthenticationWithTenant(
          localStorage.getItem(LocalStorageEnum.Tenant) as string,
        )) as string;

      if (!token) token = (await this.authService.getIdToken()).getJwtToken();
    } catch (e) {
      // continue regardless of error
    }

    return token;
  }

  async getRefreshToken(parameter: SharedParameterService) {
    let token;
    try {
      if (parameter)
        token = await this.supportLoginService.checkAndGetRefreshToken(
          parameter,
        );
      else token = (await this.authService.refreshIdToken()).getJwtToken();
    } catch (e) {
      console.log(e);
    }

    return token;
  }

  removeStoredTenant() {
    localStorage.setItem(LocalStorageEnum.Tenant, '');
  }

  resetLocalStorage() {
    localStorage.setItem('ROLE', '');
    localStorage.setItem('TENANT', '');
    localStorage.setItem('USER', '');
    localStorage.setItem('LOG_EMAIL', '');
  }

  public async setData(data: LoginModel) {
    await this.authService.login(data.email, data.password);
    await this.authService.checkAuthenticated();
    this.roleAs = LoginRole.Customer;
    localStorage.setItem('ROLE', this.roleAs);
    localStorage.setItem('LOG_EMAIL', '');
    await this.logLogin();
  }

  async logLogin(): Promise<void> {
    const token = (await this.authService.getIdToken()).getJwtToken();
    await Logger.log(LogAction.Login, token, this.loggingService);
  }

  set supportUser(val: SocialUser | undefined) {
    if (!val) return;
    this.googleAuthState.next(val);
    this.roleAs = LoginRole.Support;
    localStorage.setItem('ROLE', this.roleAs);
    if (val instanceof SocialUser) localStorage.setItem('LOG_EMAIL', val.email);
    else localStorage.setItem('LOG_EMAIL', '');
  }

  redirectToLoginPage() {
    void this.router.navigate(['']);
  }
}
