import { TranslateService } from "@ngx-translate/core";

export class Translation {

  static initTranslateService(translate: TranslateService): TranslateService {
    translate.addLangs(['de', 'en']);
    translate.setDefaultLang('de');
    translate.use('de');
    return translate;
  }

}
