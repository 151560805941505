import { FieldDefinition } from '../models/api/fieldDefinition';

export class MappingDataProvisioning {
  static GetPrefixedData(data: FieldDefinition[]): FieldDefinition[] {
    if (!data || data.length == 0)
      return data;

    return JSON.parse(JSON.stringify(data));
  }
}
