import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Translation } from '@mapping-tool/lib';

@Component({
  selector: 'mapping-tool-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent {
  
  links = [
    { name: 'immowelt-Software', href: 'https://www.immowelt-software.de/' },
    { name: 'Kontakt', href: 'https://www.immowelt-software.de/kontakt' },
    { name: 'Datenschutz', href: '/privacy' },
    { name: 'Impressum', href: '/imprint' },
    { name: 'Cookie Einstellungen', href: '#' },
  ];

  constructor(
    public translate: TranslateService) {
      this.translate = Translation.initTranslateService(this.translate);
  }
}
