<div class="bg-gray-50">
    <mapping-tool-header title="{{ 'Header.chooseTenant' | translate }}"></mapping-tool-header>
    <div class="mt-6 sm:mx-auto sm:w-full sm:max-w-md">        
    <div class="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10">

        <div *ngIf="showError" class="rounded-md bg-red-50 p-4 mb-4">
            <div class="flex">
            <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                </svg>
            </div>
            <div class="ml-3">
                <h3 class="font-sans text-sm font-medium text-red-800">{{ 'Warning.info' | translate }}</h3>
                <div class="mt-2 text-sm text-red-700">
                <ul role="list" class="font-sans list-disc space-y-1 pl-5">
                    <li>{{ 'Warning.noDataLoadDescription' | translate }}</li>
                    <li *ngIf="showChooseTenantErrorEnum === 1">{{ 'ChooseTenant.ErrorCustomerNotExist' | translate }}</li>
                    <li *ngIf="showChooseTenantErrorEnum === 2">{{ 'ChooseTenant.ErrorNoAccessToken' | translate }}</li>                    
                </ul>
                </div>
            </div>
            </div>
        </div>
    
        <div *ngIf="isLoading" class="min-h-28 text-center" >
            <div class="inline-block h-8 w-8  animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status"></div>
        </div>        

        <form class="space-y-6" action="#" method="POST" [formGroup]="form" (ngSubmit)="onSubmit()" name="form" novalidate>
            <div>
                <label for="contractId" class="font-sans block text-base font-medium leading-6 text-gray-900">{{ 'ChooseTenant.customIdInputLabel' | translate }}</label>
                <div class="mt-2">                
                    <input id="contractId" name="contractId" type="number" formControlName="contractId"  required class="font-sans block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base sm:leading-6" />
                </div>
            </div>

            <button type="submit" (keyup.enter)="onSubmit()" [disabled]="form.invalid" class="font-sans flex w-full justify-center rounded-md bg-yellow-300 px-3 py-2 text-base font-semibold text-gray-700 shadow-sm hover:bg-yellow-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-yellow-300
                group-invalid:pointer-events-none group-invalid:opacity-30">{{ 'ChooseTenant.submit' | translate }}
            </button>
        </form>
    </div>

    <div *ngIf="!showError && tenantSuccessfullyChosen" class="bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10 mt-6 text-center">
        <h3 class="font-sans mt-2 text-sm font-semibold text-gray-900">Tenant</h3>
        <div class="font-sans mt-1 text-sm text-gray-500">{{customer.companyName}}</div>
        <div class="font-sans mt-1 text-sm text-gray-500">{{customer.tenantUuid}}</div>
        <div class="font-sans mt-1 text-sm text-gray-500">{{loggedInUser}}</div>        
    </div>

    <div  class="mt-10 inline-flex space-x-1 pt-8 content-center w-full">
        <button (click)="jumpToMappingPage()" *ngIf="!showError && tenantSuccessfullyChosen" class="font-sans flex w-full justify-center rounded-md bg-yellow-300 py-2 text-base font-semibold text-gray-700 shadow-sm hover:bg-yellow-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-yellow-300">
            {{ 'ChooseTenant.jumpToMappingPage' | translate }}
        </button>
    </div>
</div>
</div>
