import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { LoginService } from './login.service';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { EnvironmentConfig } from '../models/environment-config';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService {
  constructor(private loginService: LoginService, 
    private router: Router,
    private environmentService: EnvironmentService
    ) {}

  async canActivate(
    route: ActivatedRouteSnapshot
  ): Promise<
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree
  > {
    if (!this.loginService.environmentConfig) {
      const config: EnvironmentConfig = this.environmentService.getConfig();
      this.loginService.environmentConfig = config;
    }

    if (!(await this.loginService.customerOrSupportIsAuthenticated())) {
      await this.redirectToLoginPage();
      return false;
    }

    const role = this.loginService.getRole;
    if (Object.keys(route.data).length > 0) {
      let isPermitted = true;
      if (route.data['permittedRoles']) {
        isPermitted = route.data['permittedRoles'].some(
          (x: string) => x === role
        );
        if (isPermitted) {
          await this.redirectToLoginPage();
          return false;
        }
      }

      if (route.data['role'] !== role) {
        await this.redirectToLoginPage();
        return false;
      }
    }

    return true;
  }

  redirectToLoginPage() {
    return this.router.navigateByUrl('/');
  }
}
