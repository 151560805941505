import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { EnvironmentConfig } from '../models/environment-config';
import { HttpClient } from '@angular/common/http';
import { AuthConfig } from '../models/environment';
import { Observable } from 'rxjs';
import { Amplify, Auth, Hub } from 'aws-amplify';
import { CognitoUserSession, CognitoUser } from 'amazon-cognito-identity-js';


@Injectable({
  providedIn: 'root'
})
export class AmplifyWrapperService {
  private auth!: AuthConfig;

  constructor(
    private service: EnvironmentService,
    private http: HttpClient) {
  }

  async setConfig(): Promise<void> {
    if (this.auth) {
      return;
    }

    return new Promise<void>((resolve) => {
      const config: EnvironmentConfig = this.service.getConfig();
      this.getAuthConfig(config)
        .subscribe((auth: AuthConfig) => {
          const configAuth = {
            ...auth,
            storage: localStorage,
          };

          Amplify.configure({
            Auth: configAuth
          });
          Auth.configure({
            Auth: configAuth
          });

          this.auth = auth;
          resolve();
        });
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSessionRefreshFailure(func: (event: any) => any): void {
    Hub.listen('auth', (data) => {
      if (data.payload.event === 'tokenRefresh_failure') {
        func(data.payload);
      }
    });
  }

  getAuthConfig(config: EnvironmentConfig): Observable<AuthConfig> {
    return this.http.get<AuthConfig>(config.authenticationConfigUrl + '/auth-config');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async currentAuthenticatedUser(): Promise<CognitoUser | any> {
    await this.setConfig();
    return Auth.currentAuthenticatedUser();
  }

  async currentSession(): Promise<CognitoUserSession> {
    await this.setConfig();
    return Auth.currentSession();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async signIn(username: string, password: string): Promise<CognitoUser | any> {
    await this.setConfig();
    return Auth.signIn({
      username,
      password
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async completeNewPassword(user: CognitoUser, newPassword: string): Promise<CognitoUser | any> {
    await this.setConfig();
    return Auth.completeNewPassword(
      user,
      newPassword,
      {}
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async signOut(): Promise<any> {
    await this.setConfig();
    return await Auth.signOut();
  }
}
