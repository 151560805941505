import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnvironmentService, LoginService } from '@mapping-tool/lib';
import { SocialAuthService } from '@abacritt/angularx-social-login';

@Component({
  selector: 'mapping-tool-support-google-login',
  templateUrl: './support-google-login.component.html',
  styleUrls: ['./support-google-login.component.scss'],
})
export class SupportGoogleLoginComponent implements OnInit {
  constructor(
    private authService: SocialAuthService,
    private loginService: LoginService,
    private ngZone: NgZone,
    private router: Router,
    private envService: EnvironmentService,
  ) {}

  ngOnInit() {
    this.authService.authState.subscribe((user) => {
      this.loginService.config = this.envService.getConfig();      
      this.loginService.setSupportGoogleData = user;

      if (this.loginService.supportGoogleAuthState) {
        this.loginService.removeStoredTenant();
        this.redirectToTenantInputPage();
      }
      else
        console.log('google Support-Login nicht möglich: evtl. ist die E-Mail nicht zugelassen.')        
    });
  }

  redirectToTenantInputPage() {
    this.ngZone.run(() => {
      void this.router.navigate(['choose-tenant']);
    });
  }
}
