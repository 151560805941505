import { Injectable } from '@angular/core';
import { EnvironmentConfig } from '../models/environment-config';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  constructor(private config: EnvironmentConfig) {
    // This is intentional
  }

  getConfig() {
    return this.config;
  }
}


