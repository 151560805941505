import { FieldDefinitionFieldTypeEnum } from "./fieldDefinitionFieldTypeEnum";
import { TargetEntityTypeEnum } from "./targetEntityTypeEnum";
import { MasterDataListItem } from "./masterDataListItem";
import { MasterDataHierarchicalListItem } from "./masterDataHierarchicalListItem";
import { MappingAggregatesEnum } from "./mappingAggregatesEnum";

export interface MappableFieldDefinition {
    recordKey: string;
    groupName: string;
    fieldType: FieldDefinitionFieldTypeEnum;
    name: string;
    targetEntityType: TargetEntityTypeEnum;
    mappingAggregatesEnum: MappingAggregatesEnum;
    masterDataList: MasterDataListItem[];
    masterDataHierarchicalList: MasterDataHierarchicalListItem[];
    fixedSourceField: string;
    isReadOnly: string;
}
