import { LogAction } from "../models/log-action";
import { LogRecord } from "../models/log-recpord";
import { LoggingDataService } from "../services/logging.data.service";

export class Logger{
    static async log(
      logAction: LogAction,
      token: string,
      service: LoggingDataService,
      logInfo = '',
          ): Promise<boolean> {

            const _email = localStorage.getItem('LOG_EMAIL');
            const _tenant = localStorage.getItem('TENANT');
            if(_tenant === null) return false;

            const supportLog: LogRecord = {
                tenantUuid: _tenant as string,
                email: _email as string,
                action: logAction,
                info: logInfo,
                timeStamp: new Date(),
            };

            try {
              const result = await service.putLog(supportLog, token);
              if (result === false) return false;
            } catch {
              return false;
            }
            return true;
          }
}
