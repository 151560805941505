import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoginModel, LoginService, Translation } from '@mapping-tool/lib';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mapping-tool-customer-login',
  templateUrl: './customer-login.component.html',
  styleUrls: ['./customer-login.component.scss'],
})
export class CustomerLoginComponent implements OnInit {
  public isPasswordVisible = false;
  form: FormGroup = {} as FormGroup;
  passwordError = false;
  usernameError = false;
  email ='';

  constructor(
    private fb: FormBuilder,
    private translate: TranslateService,
    private loginService: LoginService) {
    this.translate = Translation.initTranslateService(this.translate);
  }

  async ngOnInit() {
    this.form = this.fb.group({
      email: '',
      password: '',
    });
  }

  async onSubmit() {
    this.email = this.form.get('email')?.value;
    const password = this.form.get('password')?.value;
    this.usernameError = !this.email;
    this.passwordError = !password;

    if (!this.email || !password) {
      return;
    } else {
      await this.loginService
        .setCustomerData({ email: this.email, password: password } as LoginModel)
        .catch((error) => {console.log(error)});
    }
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }
}
