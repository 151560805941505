
export interface FieldTypeRuleWarningInfo {
  header: string;
  descriptions: FieldTypeRuleWarningDescriptions[];
  hasWarning: boolean;
}

export interface FieldTypeRuleWarningDescriptions {
  description: string;
  elementId: string;
}
