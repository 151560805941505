export class EnvironmentConfig {
  production: boolean  | undefined;
  languageUrl: string  | undefined;
  baseUrl: string  | undefined;
  authenticationConfigUrl: string  | undefined;
  apiBaseUrl: string  | undefined;
  permissionUrl: string  | undefined;
  actionApiUrl: string  | undefined;
  actionUrl: string  | undefined;
  addressUrl: string  | undefined;
  cognitoServiceUrl: string  | undefined;
  managementServiceUrl: string  | undefined;
  migrationApiUrl: string  | undefined;
  mappingApiUrl: string  | undefined;
  mappingUrl: string  | undefined;
  sharedUrl: string  | undefined;
  contentServiceBaseUrl: string | undefined;
  contentServiceBasicAuth: string | undefined;
  tealiumStage: string | undefined;
  features: { [key: string]: boolean } | undefined;
  stage: string | undefined;
  supportLegitimateEmails: string[] | undefined;
}
