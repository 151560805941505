import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpRequestOptions } from './http-request-options';
import { EnvironmentService } from './environment.service';
import { MappableFieldDefinition } from '../models/api/mappableFieldDefinition';
import { FieldDefinition } from '../models/api/fieldDefinition';
import { Mapping } from '../models/api/mappingModel';
import { QueryResultSet } from '../models/api/query-list';

@Injectable({
  providedIn: 'root',
})
export class MappingDataService {
  private mappingApiUrl: string;  

  constructor(private httpClient: HttpClient,
    environment: EnvironmentService
  ) {
    this.mappingApiUrl = environment.getConfig().mappingApiUrl || '';
  }

  async getMappableFieldDefinitions(
    token: string,
  ): Promise<MappableFieldDefinition[]> {

    const url = `${this.mappingApiUrl}/mappable-field-definitions`;
    const definitionsMasterdata : MappableFieldDefinition[] = [];
    return await this.nextPageRecursion(definitionsMasterdata, url, token);
  }

  async getFieldDefinitionsMasterdata(
    token: string,
  ): Promise<FieldDefinition[]> {

    const url = `${this.mappingApiUrl}/field-definitions-masterdata?limit=99999`;
    const definitionsMasterdata : FieldDefinition[] = [];
    return await this.nextPageRecursion(definitionsMasterdata, url, token);
  }

  async getMappings(
    token: string,
  ): Promise<Mapping[]> {

    const url = `${this.mappingApiUrl}/mappings`;
    const mappings : Mapping[] = [];
    return await this.nextPageRecursion(mappings, url, token);
  }

  async putMappings(mappings: Mapping[], token: string): Promise<Mapping> {
    if (!token || !mappings) {
      return {} as Mapping;
    }

    const url = `${this.mappingApiUrl}/mappings`;
    return new Promise((resolve) => {
      this.httpClient
        .put(url, mappings, this.getHeaderOptions(token, undefined))
        .subscribe({
          next: (value) => resolve(value as Mapping),
          error: () => resolve({} as Mapping),
        });
    });
  }

  async deleteMappings(token: string): Promise<boolean> {
    if (!token) {
      return false;
    }

    const url = `${this.mappingApiUrl}/mappings`;
    return new Promise((resolve) => {
      this.httpClient
        .delete(url, this.getHeaderOptions(token, undefined))
        .subscribe({
          next: (value) => resolve(value as boolean),
          error: () => resolve(false),
        });
    });
  }

  private async nextPageRecursion<T>(
    data: T[],
    url: string,
    token: string,
    nextPageToken?: string
  ): Promise<T[]> {
    return new Promise((resolve) => {
      this.httpClient
        .get<QueryResultSet<T>>(url, this.getHeaderOptions(token, nextPageToken))
        .subscribe({
          next: (result) => {
            data = [...data, ...result.data];
            if (result.nextPageToken) {
              void this.nextPageRecursion(data, url, token, result.nextPageToken).then((result) =>
                resolve(result)
              );
              return;
            }

            resolve(data);
          },
          error: () => {
            resolve(data);
          },
        });
    });
  }

  private getHeaderOptions(token: string, nextPageToken: string | undefined) : HttpRequestOptions {
    const options = {
      headers: { authorization: 'Bearer ' + token },
      params: nextPageToken ? { pagetoken: nextPageToken } : undefined,
    } as HttpRequestOptions;
    return options;
  }  
}
