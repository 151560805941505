export interface ManagementCustomer {
  tenantUuid: string;
  customerId: string;
  contractId: number;
  licenseCount: number;
  edition: string;
  isDomestic: boolean;
  companyName: string;
  serviceVersion: string;  
}
export interface ManagementCustomerWithToken extends ManagementCustomer{
  idToken: string;
}
