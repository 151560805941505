import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { HttpClientGateway } from "./services/http-client-gateway";

@NgModule({
  imports: [CommonModule],
})

export class LibModule {
  static forRoot(): ModuleWithProviders<LibModule> {
    return {
      ngModule: LibModule,
      providers: [HttpClientGateway]
    }
  }
}
