<div class="min-h-28 mx-4 md:mx-0 border border-gray-300 rounded-2xl px-6 py-5 mb-4 bg-white relative overflow-hidden">
    <div class="flex md:items-center md:justify-between">
        <div class="min-w-0 flex-1">
            <h2 class="font-sans text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">{{title}}</h2>
        </div>
        <button type="button" *ngIf="isLoggedIn" (click)="logout()" data-tooltip-target="logout-button-tooltip">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" transform="scale(-1 1)" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
            </svg>
        </button>
    </div>
</div>
