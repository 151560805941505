import { Injectable } from '@angular/core';

export enum LocalStorageEnum {
  Undefined = 'UNDEFINED',
  Role = 'ROLE',
  Tenant = 'TENANT',
  User = 'USER',
  LogMail = 'LOG_EMAIL',
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {

  static getItem(key: LocalStorageEnum) {
    if (Object.values(LocalStorageEnum).includes(key)) {
      return localStorage.getItem('key');
    }
    return ''
  }
  
}
