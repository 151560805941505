export enum FieldDefinitionFieldTypeEnum {
  Text = 1, 
  Numeric = 2, 
  Date = 3, 
  Combo = 4, 
  List = 5, 
  Option = 6, 
  NumericFromTill = 7, 
  DateFromTill = 8, 
  Memo = 9, 
  Check = 10, 
  Currency = 11, 
  CurrencyFromTill = 12, 
  HierarchicalList = 13, 
  AreaCode = 14, 
  WholeNumber = 15, 
  WholeNumberFromTill = 16, 
  MonthYear = 17, 
  Fraction = 18, 
  AreaCodeList = 19, 
  FreeTextCombo = 20, 
  Image = 21
}
