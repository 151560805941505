import { FieldDefinition } from "./api/fieldDefinition";
import { Mapping } from "./api/mappingModel";
import { MasterDataListItem } from "./api/masterDataListItem";

export interface AssignedMasterData {
  targetId: number;
  sourceId: number;
  targetName: string;
}

export interface UiStates {
  recordKey: string;
  assignedRecordKey1: string;
  assignedRecordKey2: string;  
  hasMasterData: boolean;
  hasMasterDataHierarchical: boolean;
  isAnotherFieldDefinitionButtonClicked: boolean;
  initFieldDefinition: FieldDefinition;
  initFieldDefinition2: FieldDefinition;
  initMapping: Mapping;
  masterData: MasterDataListItem[];
  masterDataHierarchical: MasterDataListItem[];
  assignedMasterData1: AssignedMasterData[];
  assignedMasterData2: AssignedMasterData[];  
}
