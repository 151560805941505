import { FieldDefinition } from '../models/api/fieldDefinition';
import { FieldDefinitionFieldTypeEnum } from '../models/api/fieldDefinitionFieldTypeEnum';
import { MappableFieldDefinition } from '../models/api/mappableFieldDefinition';
import { MappingAggregatesEnum } from '../models/api/mappingAggregatesEnum';
import { MasterDataListItem } from '../models/api/masterDataListItem';
import { SourceField } from '../models/api/sourceField';
import { TargetEntityTypeEnum } from '../models/api/targetEntityTypeEnum';
import { AssignedMasterData, UiStates } from '../models/ui-states';
import { FieldTypeRule } from './field-type-rule';

export class MappingUiLogic {
  static IsFirstRecordKeySet(state: UiStates): boolean {
    if (!state) return false;
    return state.assignedRecordKey1?.length > 0;
  }

  static GetFieldDefinitionFilterByAggregateSorted(
    definitions: FieldDefinition[],
    aggregate: string,
    mappable: MappableFieldDefinition
  ): FieldDefinition[] {
    const fieldDefinitions = [...definitions];
    if (!fieldDefinitions || fieldDefinitions.length == 0) return [];

    const mappingAggregateName = MappingAggregatesEnum[mappable.mappingAggregatesEnum]?.toString();

    // Estate
    if (aggregate === 'Estate') {
      const defs = fieldDefinitions
      .filter((x) => !!x.aggregates?.find((f) => mappingAggregateName === f))
      .filter((x) => FieldTypeRule.isMappingAllowed(x.fieldType, mappable.fieldType));

      const copy = JSON.parse(JSON.stringify(defs));
      defs.forEach(x => this.addPostfixIfContainsMultipleOccurrenceNameForFieldDefinition(x, copy));
      return defs.sort((a, b) => a.name?.localeCompare(b.name));
    }

    // Address
    const defs = fieldDefinitions
      .filter((x) => !!x.aggregates?.find((f) => mappingAggregateName === f))
      .filter((x) => FieldTypeRule.isMappingAllowed(x.fieldType, mappable.fieldType));
    return defs.sort((a, b) => a.name?.localeCompare(b.name));
  }

  static GetFieldDefinitionForSpacesSorted(
    definitions: FieldDefinition[],
    def: MappableFieldDefinition
  ): FieldDefinition[] {
    if (def.groupName === 'Flächen') {
      return this.loadFieldDefinitionForSpaces(definitions).sort((a, b) => a.name?.localeCompare(b.name));     
    }
    
    return [] as FieldDefinition[];
  }

  static GetSpaceIdByName(name: string, fields: FieldDefinition[]): string {
    if (!fields) return '';

    const sObjFlaechen = fields.filter((x) =>  x.recordKey == 'SObjFlaechen');
    if (!sObjFlaechen || sObjFlaechen.length == 0 || sObjFlaechen[0].masterDataList?.length == 0)
      return '';
    
    const space = sObjFlaechen[0].masterDataList.filter(x=>x.name === name);
    if (space.length == 1)
      return space[0].id.toString();
    return ''
  }

  static GetInitialAssignedMasterData(sourceField: SourceField, mappable: MappableFieldDefinition): AssignedMasterData[] {
    const ret: AssignedMasterData[] = [];
    if (sourceField?.items === undefined || sourceField?.items?.length === 0) return ret;
    if (mappable.masterDataList?.length === 0) return ret;

    mappable.masterDataList.forEach(m=> {
      const sourceId = sourceField?.items.findIndex(s=>s.target === m.id.toString());
      if (sourceId === -1)
        ret.push({targetId: m.id, sourceId: -1, targetName: m.name});
      else
        ret.push({targetId: m.id, sourceId: Number(sourceField?.items[sourceId].source), targetName: m.name});
      });
    
    return ret;
  }

  static EnrichMappables(mappableFields: MappableFieldDefinition[]) {
    mappableFields.forEach(db=>{
        if (db.targetEntityType === TargetEntityTypeEnum.Address 
          || db.targetEntityType.toString() === TargetEntityTypeEnum[TargetEntityTypeEnum.Address]) {

        const adr = JSON.parse(JSON.stringify(db));

        db.mappingAggregatesEnum = MappingAggregatesEnum.Person;
        db.recordKey = MappingAggregatesEnum.Person + '_' + db.recordKey;

        adr.mappingAggregatesEnum = MappingAggregatesEnum.Company;
        adr.recordKey = MappingAggregatesEnum.Company + '_' + adr.recordKey;

        mappableFields.push(adr);
      }
      
      if (db.targetEntityType === TargetEntityTypeEnum.Estate 
        || db.targetEntityType.toString() === TargetEntityTypeEnum[TargetEntityTypeEnum.Estate])
          db.mappingAggregatesEnum = MappingAggregatesEnum.Estate;
    });
  }  

  static loadFieldDefinitionForSpaces(fields: FieldDefinition[]) : FieldDefinition[]{
    const sObjFlaechen = fields.filter((x) =>  x.recordKey == 'SObjFlaechen');
    if (!sObjFlaechen || sObjFlaechen.length == 0 || sObjFlaechen[0].masterDataList?.length == 0)
      return [] as FieldDefinition[];

    const spaces: FieldDefinition[] = sObjFlaechen[0].masterDataList.map(x => {
      return {
        name: x.name + this.addPostfixIfContainsMultipleOccurrenceName(x, sObjFlaechen[0].masterDataList),
        recordKey: x.id.toString(),
        aggregates: ['Estate'],
        groupName: 'Flächen',
        fieldType: FieldDefinitionFieldTypeEnum.Text,        
        masterDataList: [],
        masterDataHierarchicalList: []
      } as FieldDefinition;
    });

    return spaces;
  }

  static SetAssignedMasterDataTarget(state: UiStates, mappable: MappableFieldDefinition[]) {
    const mbl = mappable.filter(x=>x.recordKey === state.recordKey)[0];
    if (!mbl)
      return;
    const masterDataList = mbl.masterDataList;
    state.assignedMasterData1 = [];
    if (state.masterData.length == 0)
      return;
    masterDataList.forEach(x=> {
      state.assignedMasterData1.push({targetId: x.id, sourceId: -1, targetName: x.name})
      });
  }

  static addPostfixIfContainsMultipleOccurrenceName(x: MasterDataListItem, masterDataList: MasterDataListItem[]): string {
    if (masterDataList.filter(m => m.name == x.name).length == 2)
      return ' (' + x.id.toString() + ')';
    return '';
  }

  static addPostfixIfContainsMultipleOccurrenceNameForFieldDefinition(x: FieldDefinition, fieldDefinition: FieldDefinition[]) {
    if (fieldDefinition.filter(m => m.name == x.name).length == 2)
      x.name += ' (' + x.recordKey.toString() + ')';
  }

  static getMoveAggregates(aggregate: string) {
    switch (aggregate) {
      case 'Address':
        return ['Person', 'Company'];
      case 'Estate':
        return ['Estate'];
    }
    return [];
  }
}
