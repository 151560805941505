import { Component, Inject, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration} from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest } from '@azure/msal-browser';
import { EnvironmentService, LoginService } from '@mapping-tool/lib';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mapping-tool-support-microsoft-login',
  templateUrl: './support-microsoft-login.component.html',
  styleUrls: ['./support-microsoft-login.component.css'],
})
export class SupportMicrosoftLoginComponent implements OnDestroy {
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private loginService: LoginService,
    private ngZone: NgZone,    
    private router: Router,
    private envService: EnvironmentService,
  ) {}

  login() {
    this.subscribeBroadcastService();

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    }
  }

  subscribeBroadcastService() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None,
        ),
        takeUntil(this._destroying$),
      )
      .subscribe(() => {
        this.setLoginStateAndRedirect();
      });
  }

  setLoginStateAndRedirect() {
    this.loginService.config = this.envService.getConfig();
    if (this.loginService.supportMicrosoftAuthState) {
      this.loginService.removeStoredTenant();
    } else
      console.log(
        'Microsoft Support-Login nicht möglich: evtl. ist die E-Mail nicht zugelassen.',
      );
    this.redirectToTenantInputPage();
  }

  redirectToTenantInputPage() {
    this.ngZone.run(() => {
      void this.router.navigate(['choose-tenant']);
    });  
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
