import { Buffer } from 'buffer';
import { EnvironmentConfig } from '../models/environment-config';
import { EnvironmentService } from './environment.service';
import { HttpClientGateway } from './http-client-gateway';
import {
  ManagementCustomer,
  ManagementCustomerWithToken,
} from '../models/api/managementCustomer';
import { ChooseTenantErrorEnum } from '../models/api/chooseTenantErrorEnum';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SharedParameterService } from './shared-parameter.service';
@Injectable({
  providedIn: 'root',
})
export class SupportLoginService {
  readonly managementServiceUrl: string;
  readonly cognitoServiceUrl: string;
  readonly clientId: string;
  readonly clientSecret: string;
  config: EnvironmentConfig;

  constructor(
    environment: EnvironmentService,
    private readonly httpClientGateway: HttpClientGateway,
    private httpClient: HttpClient
  ) {
    this.config = environment.getConfig();
    this.managementServiceUrl = this.config.managementServiceUrl ?? '';
    this.cognitoServiceUrl = this.config.cognitoServiceUrl ?? '';
    this.clientId = this.getClientId();
    this.clientSecret = this.getClientSecret();
  }

  async getAuthentication(
    licenseId: number,
  ): Promise<ManagementCustomerWithToken | ChooseTenantErrorEnum> {
    const accessToken = await this.getAccessToken();
    if (!accessToken) return ChooseTenantErrorEnum.NoAccessToken;

    const customer = await this.getCustomer(licenseId, accessToken);
    if (!customer?.tenantUuid) return ChooseTenantErrorEnum.CustomerNotExist;

    const idToken = await this.getIdToken(customer.tenantUuid, accessToken);
    if (!idToken) return ChooseTenantErrorEnum.NoIdToken;

    return { ...customer, idToken } as ManagementCustomerWithToken;
  }

  async getAuthenticationWithTenant(tenantUuid: string): Promise<string|undefined> {
    const accessToken = await this.getAccessToken();
    if (!accessToken) return;

    const idToken = await this.getIdToken(tenantUuid, accessToken);
    if (!idToken) return;

    return idToken;
  }

  async getAccessToken(): Promise<string> {
    const body = new URLSearchParams();
    body.set('grant_type', 'client_credentials');
    body.set('clientId', this.clientId);
    body.set('clientSecret', this.clientSecret);
    body.set('scope', 'management-api/migrations.token');

    const response = await this.httpClientGateway.postAsForm(
      this.cognitoServiceUrl,
      'oauth2/token',
      body.toString(),
      'Basic ' +
        Buffer.from(this.clientId + ':' + this.clientSecret).toString('base64'),
    );

    return JSON.parse(response.body)?.access_token;
  }

  async getCustomer(
    licenseId: number,
    accessToken: string,
  ): Promise<void | ManagementCustomer> {
    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + accessToken);
    const response = await fetch(
      this.managementServiceUrl + `customers/${licenseId}`,
      {
        method: 'GET',
        headers: headers,
        redirect: 'follow',
      },
    )
      .then((response) => response.text())
      .then((text) => {
        if (!text || text.length < 1) return {} as ManagementCustomer;
        return JSON.parse(text) as Promise<ManagementCustomer>;
      })
      .catch();
    return response;
  }

  async getIdToken(
    tenantUuid: string,
    accessToken: string,
  ): Promise<void | string> {
    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + accessToken);

    const response = await fetch(
      this.managementServiceUrl + `migrations/${tenantUuid}/token`,
      {
        method: 'POST',
        headers: headers,
        redirect: 'follow',
      },
    )
      .then((response) => response.text())
      .then((text) => {
        if (!text || text.length < 1) return '';
        return JSON.parse(text)?.idToken as Promise<string>;
      })
      .catch();
    return response;
  }

  async checkAndGetRefreshToken(parameter: SharedParameterService): Promise<string> {
    if (parameter.token && !this.isTokenExpired(parameter.token))
      return parameter.token;

    const result = await this.getRefreshToken(parameter.contractId);
    if (result) return result;
    return '';
  }

  async getRefreshToken(contractId: number): Promise<void | string> {
    const accessToken = await this.getAccessToken();
    if (!accessToken) return;

    const customer = await this.getCustomer(contractId, accessToken);
    if (!customer?.tenantUuid) return;

    const idToken = await this.getIdToken(customer.tenantUuid, accessToken);
    return idToken;
  }

  getClientId(): string {
    let id = '';
    this.getClientJson().subscribe({
      next: (value) => (id = value.migrationClientId),
      error: (err) => {
        console.error('Error occurred:', err);
      }
    });

    if (!id && this.config?.stage == 'dev')
      return '4p8b4qbqv608448b7lahedcbq5';
    if (!id && this.config?.stage == 'preview')
      return '469ha5b1n20674rj9ull40ai26';
    if (!id && this.config?.stage == 'live')
      return '48kclfefkba8305gnf7mdeospv';
    return id;
  }

  getClientSecret(): string {
    if (this.config?.stage == 'live') {
      return '16o8u46svj40gst8cv301dvtg4e9kmd0kbea3af33dcm4mfmn2n9';
    }
    if (this.config?.stage == 'preview') {
      return '1tu0n9t0gg5b6d3du6q67u5qjmdmu6a3in9musu309d4vnas8sk4';
    }
    if (this.config?.stage == 'dev') {
      return '8dbung3mivf61fisoeuj7os5131uim7uofs8ounfc9n78pebogo';
    }

    return '';
  }

  isTokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getClientJson(): Observable<any> {
    return this.httpClient.get('./assets/client.json');
  }
}
