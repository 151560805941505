export interface Environment {
  apiBaseUrl: string;
  auth: AuthConfig;
}

export interface AuthConfig {
  authenticationFlowType: string;
  region: string;
  userPoolId: string;
  userPoolWebClientId: string;
  storage?: Storage;
}
