import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { MappingPageComponent } from './pages/mapping-page/mapping-page.component';
import { ChooseTenantPageComponent } from './pages/choose-tenant-page/choose-tenant-page.component';
import { AuthGuardService, LoginRole } from '@mapping-tool/lib';

const routes: Routes = [
  { path: '', component: LoginPageComponent },
  { path: 'register', component: LoginPageComponent },
  {
    path: 'mapping',
    component: MappingPageComponent,
    canActivate: [AuthGuardService],
  },  
  {
    path: 'choose-tenant',
    component: ChooseTenantPageComponent,
    canActivate: [AuthGuardService],
    data: {
      role: LoginRole.Support,
      permittedRoles: [LoginRole.Customer],
    },
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
