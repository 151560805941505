import { Component } from "@angular/core";

@Component({
  selector: "mapping-tool-root",
  templateUrl: "./app.component.html",
  styleUrls: [],
})
export class AppComponent {
  title = "mapping-tool";
}
