
import { MappingAggregatesEnum } from "./mappingAggregatesEnum";
import { MappingSource } from "./mappingSource";
import { MappingTypeEnum } from "./mappingTypeEnum";

export interface Mapping {
    type: MappingTypeEnum;
    aggregate: MappingAggregatesEnum;
    source: MappingSource;
    targetField: string;
    defaultValue: string;
}
