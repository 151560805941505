<div class="bg-gray-50">
    <mapping-tool-header title="{{ 'Header.mapping' | translate }}"></mapping-tool-header>
    <div class="mx-auto max-w-2xl px-4 pb-24 sm:px-6 lg:max-w-7xl lg:px-8">

        <div *ngIf="dataLoadError" class="rounded-md bg-red-50 p-4">
            <div class="flex">
            <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z" clip-rule="evenodd" />
                </svg>
            </div>
            <div class="ml-3">
                <h3 class="font-sans text-sm font-medium text-red-800">{{ 'Warning.info' | translate }}</h3>
                <div class="mt-2 text-sm text-red-700">
                <ul role="list" class="font-sans list-disc space-y-1 pl-5">
                    <li>{{ 'Warning.noDataLoadDescription' | translate }}</li>
                </ul>
                </div>
            </div>
            </div>
        </div>
    
        <div *ngIf="isLoading" class="min-h-28 text-center" >
            <div class="inline-block h-8 w-8  animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]" role="status"></div>
        </div>

        <div *ngIf="!isLoading && fieldTypeRuleWarningInfo?.hasWarning && !closeWarningBox" class="sticky rounded-md bg-yellow-300 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="font-sans text-sm font-medium text-gray-900">{{fieldTypeRuleWarningInfo.header}}</h3>
                <div class="font-sans mt-2 flex-1 text-sm text-gray-900">
                  <ul role="list" class="font-sans list-disc space-y-1 pl-5">
                    <li *ngFor="let txt of fieldTypeRuleWarningInfo.descriptions?.sort()" class="flex cursor-pointer hover:text-gray-500">
                      <a class="inline-flex" (click)="onClickWarningToControl('m_' + txt.elementId)">{{txt.description}}</a>
                    </li>
                  </ul>
                </div>
                <div class="absolute right-0 top-0 pr-4 pt-4">
                    <button type="button" (click)="onClickCloseWarningBox()" class="rounded-md bg-white bg-yellow-300 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span class="sr-only">Close</span>
                      <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>                
              </div>
            </div>
          </div>        

        <form class="bg-gray-50" [formGroup]="form" name="form">
            <div *ngFor="let aggregate of sortByAggregates()" >
                <details *ngIf="!isLoading" class="collapse-open bg-base-200 pt-6" open>
                    <summary class="collapse-title font-sans text-lg font-medium text-gray-900 border-b border-gray-200 mt-6 cursor-pointer">{{ 'Glosar.' + aggregate | translate }}
                    </summary>

                    <div *ngFor="let grouped of sortByGroupNameDistinct(aggregate)" >
                        <div class="font-sans text-lg font-medium text-gray-900 border-b border-gray-200 mt-6 cursor-pointer">{{grouped.groupName}}
                        </div>

                        <div class="collapse-content grid grid-cols-1 lg:grid-cols-2">
                            <div *ngFor="let mappable of filterByAggregatesortByMappableFields('targetEntityType', aggregate, grouped.groupName)" >

                                <div class="mt-4 border border-gray-300 rounded-2xl   mr-3 ml-3 px-6 py-5">
                                    <label for="m_{{mappable.name}}" *ngIf="!showIfIsSpace(mappable)" class="font-sans block text-sm font-medium text-gray-700">{{mappable.name}} {{getUiTargetNamePostfix(mappable)}}</label>

                                    <select id="m_{{mappable.recordKey}}" name="m_{{mappable.name}}" disabled="{{mappable.isReadOnly}}"  *ngIf="!showIfIsSpace(mappable)" (change)="onFieldDefinitionMasterDataSelectionChanged($event)" class="font-sans mt-6 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                        <option [value]="">{{ 'Mapping.noFieldDefinitionMasterdataChosen' | translate }}</option>
                                        <option *ngFor="let def of getFieldDefinitionFilterByAggregateSorted(aggregate, mappable);" [value]="def.recordKey" [selected]="isFirstFieldSelected(def, mappable)" >{{def.name}}</option>
                                    </select>             

                                    <label for="ms_{{mappable.name}}" *ngIf="showIfIsSpace(mappable)" class="font-sans block text-sm font-medium text-gray-700">{{mappable.name}}</label>

                                    <select id="ms_{{mappable.recordKey}}" name="ms_{{mappable.name}}" *ngIf="showIfIsSpace(mappable)" (change)="onSpaceSelectionChanged($event)" class="font-sans mt-6 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                        <option [value]="">{{ 'Mapping.noFieldDefinitionMasterdataChosen' | translate }}</option>
                                        <option *ngFor="let space of getFieldDefinitionForSpacesSorted(mappable); trackBy:spaceIdentify" [value]="space.recordKey" [selected]="isSpaceFieldSelected(space, mappable)">{{space.name}}</option>
                                    </select> 

                                    <div id="mdl_{{mappable.recordKey}}" *ngIf="showFirstMasterDataList(mappable)" class="">
                                        <p class="font-sans block text-sm text-gray-700 mt-6">{{ 'Mapping.masterdata' | translate }}</p>
                                        
                                        <div class="w-full flex items-center" *ngFor="let masterdata of sortMasterDataByName(mappable.masterDataList)">
                    
                                            <div  id="mdlb_{{masterdata.id}}" class="font-sans w-1/2 mt-2">
                                                {{masterdata.name}}
                                            </div>                           
                                            <div class="w-1/2 block mt-2">
                                                <select id="md_{{masterdata.id}}" name="md_{{masterdata.id}}" (change)="onMasterDataSelectionChanged($event, mappable)" class="font-sans w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                                    <option [ngValue]="">{{ 'Mapping.noMasterdataChosen' | translate }}</option>
                                                    <option *ngFor="let opt of getMasterDataListFromSelectedField(mappable.recordKey)" [ngValue]="opt" [selected]="isFirstMasterDataSelected(opt, masterdata, mappable)">{{opt.name}}</option>
                                                </select> 
                                            </div>
                                        </div>
                                    </div>
                    
                                    <select id="m_{{mappable.recordKey}}__2" *ngIf="showAnotherFieldDefinition(mappable)" name="m_{{mappable.name}}__2" (change)="onFieldDefinitionMasterDataSelectionChanged($event)"  class="font-sans mt-6 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                        <option [value]="">{{ 'Mapping.noFieldDefinitionMasterdataChosen' | translate }}</option>
                                        <option *ngFor="let def of getFieldDefinitionFilterByAggregateSorted(aggregate, mappable);" [value]="def.recordKey" [selected]="isSecondFieldSelected(def, mappable)"  >{{def.name}}</option>
                                    </select> 
                    
                                    <div id="mdl_{{mappable.recordKey}}__2" *ngIf="showSecondMasterDataList(mappable)" >
                                        <p class="font-sans block text-sm text-gray-700 mt-6">{{ 'Mapping.masterdata' | translate }}</p>
                                        
                                        <div class="w-full flex items-center" *ngFor="let masterdata of sortMasterDataByName(mappable.masterDataList)">
                    
                                                <div  id="mdlb_{{masterdata.id}}__2" class="font-sans w-1/2 mt-2">
                                                    {{masterdata.name}}
                                                </div>                           
                                                <div class="w-1/2 block mt-2">
                                                    <select id="md_{{masterdata.id}}__2" name="md_{{masterdata.id}}__2"  class="font-sans w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                                        <option [ngValue]="">{{ 'Mapping.noMasterdataChosen' | translate }}</option>
                                                        <option *ngFor="let opt of getMasterDataListFromSelectedField(mappable.recordKey)" [ngValue]="opt" >{{opt.name}}</option>
                                                    </select> 
                                                </div>
                                        </div>
                                    </div>
                    
                                    <button type="button" id="b_{{mappable.recordKey}}" *ngIf="showAnotherFieldDefinitionButton(mappable)" class="font-sans hidden rounded-md bg-yellow-300 hover:bg-yellow-200 px-2.5 py-1.5 mt-6 text-sm font-semibold text-gray-700 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    (click)="onClickAnotherFieldDefinition($event)">{{ 'Mapping.addAnotherFieldDefinitionMasterdata' | translate }}</button>
                    
                                </div>
                            </div>
                        </div>
                    </div>
                </details>
            </div>

            <div *ngIf="!isLoading" class="mt-10 inline-flex space-x-1 border-t border-gray-200 pt-8 content-center w-full">
                <button *ngIf="!dataLoadError" (click)="btnAuto()" class="font-sans w-full justify-center rounded-md bg-yellow-300 px-3 py-2 text-base font-semibold text-gray-700 shadow-sm hover:bg-yellow-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-yellow-300">{{ 'Button.auto' | translate }}</button>
                <button type="reset" (click)="btnReset()" class="font-sans w-full justify-center rounded-md bg-yellow-300 px-3 py-2 text-base font-semibold text-gray-700 shadow-sm hover:bg-yellow-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-yellow-300">{{ 'Button.reset' | translate }}</button>
                <button *ngIf="!dataLoadError" (click)="btnSave()" type="submit" class="font-sans w-full justify-center rounded-md bg-yellow-300 px-3 py-2 text-base font-semibold text-gray-700 shadow-sm hover:bg-yellow-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-yellow-300">{{ 'Button.save' | translate }}</button>                
            </div>

            <div *ngIf="!isLoading" class="inline-flex space-x-1 border-t border-gray-200 pt-8 content-center w-full">
                <input type="checkbox" id="personsCheck" name="chkPersons" value="Personen" (click)="chkBoxClick($event)"
                    class="ml-3 w-7 h-7 text-cyan-600 border-gray-300 rounded focus:ring-0 self-start" />
                <label for="personsCheck" 
                    class="pl-3 cursor-pointer font-sans w-full justify-center rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 leading-7">{{ 'Checkbox.persons' | translate }}</label>
                <input type="checkbox" id="companiesCheck" name="chkCompanies" value="Firmen" (click)="chkBoxClick($event)"
                    class="w-7 h-7 text-cyan-600 border-gray-300 rounded focus:ring-0 self-start"/>
                <label for="companiesCheck" 
                    class="pl-3 cursor-pointer font-sans w-full justify-center rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 leading-7">{{ 'Checkbox.companies' | translate }}</label>
                <input type="checkbox" id="estatesCheck" name="chkEstates" value="Objekte" (click)="chkBoxClick($event)"
                    class="w-7 h-7 text-cyan-600 border-gray-300 rounded focus:ring-0 self-start"/>
                <label for="estatesCheck"
                    class="pl-3 cursor-pointer font-sans w-full justify-center rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 leading-7">{{ 'Checkbox.estates' | translate }}</label>
                <button (click)="btnRefeed()" class="font-sans w-full justify-center rounded-md bg-yellow-300 px-3 py-2 text-base font-semibold text-gray-700 shadow-sm hover:bg-yellow-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:bg-yellow-300">{{ 'Button.refeed' | translate }}</button>
            </div>
        </form>

        <div *ngIf="!isLoading && isSaving" class="min-h-28 text-center pt-8" >
            <svg aria-hidden="true" class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-yellow-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
            </svg>
        </div>

        <div *ngIf="dataSaveError" id="alert-2" class="flex items-center p-4 mb-4 text-red-400 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
            <svg class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <div class="font-sans mx-4 text-sm text-red-700">
                <p>{{ 'Warning.notSavedDescription' | translate }}</p>
              </div>
            <button type="button" (click)="btnDismissSaveError()" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700" data-dismiss-target="#alert-2" aria-label="Close">
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
              </svg>
            </button>
        </div>

        <div *ngIf="dataRefeedError" id="alert-2" class="flex items-center p-4 mb-4 text-red-400 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
            <svg class="flex-shrink-0 w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <div class="font-sans mx-4 text-sm text-red-700">
                <p>{{ 'Warning.notRefeededDescription' | translate }}</p>
              </div>
            <button type="button" (click)="btnDismissRefeedError()" class="ml-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700" data-dismiss-target="#alert-2" aria-label="Close">
              <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
              </svg>
            </button>
        </div>
    
        <div *ngIf="dataSaveSuccess" class="border-l-4 border-green-400 bg-green-50 p-4">
            <div class="flex">
                <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                </svg>
                </div>
                <div class="ml-3">
                    <h3 class="font-sans text-sm font-medium text-green-800">{{ 'Warning.info' | translate }}</h3>
                    <div class="font-sans mt-2 text-sm text-green-700">
                      <p>{{ 'Warning.successfullySavedDescription' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="dataRefeedSuccess" class="border-l-4 border-green-400 bg-green-50 p-4">
            <div class="flex">
                <div class="flex-shrink-0">
                <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clip-rule="evenodd" />
                </svg>
                </div>
                <div class="ml-3">
                    <h3 class="font-sans text-sm font-medium text-green-800">{{ 'Warning.info' | translate }}</h3>
                    <div class="font-sans mt-2 text-sm text-green-700">
                      <p>{{ 'Warning.successfullyRefeedDescription' | translate }}</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>  
