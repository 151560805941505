import { FieldDefinitionFieldTypeEnum } from '../models/api/fieldDefinitionFieldTypeEnum';

export class FieldTypeRule {
  static isMappingAllowed(
    sourceType: FieldDefinitionFieldTypeEnum,
    targetType: FieldDefinitionFieldTypeEnum
  ): boolean {
    if (sourceType === targetType) return true;
    if (targetType.toString() === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Text]) return true;
    if (targetType.toString() === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Memo]) return true;

    switch (sourceType.toString()) {
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Text]:
        return this.isMappingAllowedForText();
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Numeric]:
        return this.isMappingAllowedForNumeric(targetType.toString());
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Date]:
        return this.isMappingAllowedForDate(targetType.toString());
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Combo]:
        return this.isMappingAllowedForCombo(targetType.toString());
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.List]:
        return this.isMappingAllowedForList(targetType.toString());
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Option]:
        return this.isMappingAllowedForOption(targetType.toString());
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.NumericFromTill]:
        return this.isMappingAllowedForNumericFromTill(targetType.toString());
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.DateFromTill]:
        return this.isMappingAllowedForDateFromTill(targetType.toString());
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Memo]:
        return this.isMappingAllowedForMemo();
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Check]:
        return this.isMappingAllowedForCheck(targetType.toString());
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Currency]:
        return this.isMappingAllowedForCurrency(targetType.toString());
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.CurrencyFromTill]:
        return this.isMappingAllowedForCurrencyFromTill(targetType.toString());
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.HierarchicalList]:
        return this.isMappingAllowedForHierarchicalList(targetType.toString());
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.AreaCode]:
        return this.isMappingAllowedForAreaCode();
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.WholeNumber]:
        return this.isMappingAllowedForWholeNumber(targetType.toString());
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.WholeNumberFromTill]:
        return this.isMappingAllowedForWholeNumberFromTill(targetType.toString());
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.MonthYear]:
        return this.isMappingAllowedForMonthYear(targetType.toString());
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Fraction]:
        return this.isMappingAllowedForFraction(targetType.toString());
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.AreaCodeList]:
        return this.isMappingAllowedForAreaCodeList(targetType.toString());
      case FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.FreeTextCombo]:
        return this.isMappingAllowedForFreeTextCombo(targetType.toString());
    }
    return true;
  }

  static isMappingAllowedForText() {
    return true;
  }

  static isMappingAllowedForNumeric(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.NumericFromTill])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.DateFromTill]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Currency]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.CurrencyFromTill])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.WholeNumber]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.WholeNumberFromTill])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.FreeTextCombo]) return true;
    return false;
  }

  static isMappingAllowedForDate(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.DateFromTill]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.FreeTextCombo]) return true;
    return false;
  }

  static isMappingAllowedForCombo(targetType: string){
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Numeric])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.List]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Option]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Check]) return true;    
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.HierarchicalList])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.FreeTextCombo]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.WholeNumber]) return true;
    return false;
  }

  static isMappingAllowedForList(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Combo]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Option]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.HierarchicalList])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.FreeTextCombo]) return true;
    return false;
  }

  static isMappingAllowedForOption(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Combo]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.List]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Check]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.HierarchicalList])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.FreeTextCombo]) return true;
    return false;
  }

  static isMappingAllowedForNumericFromTill(
    targetType: string
  ) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Numeric]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Currency]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.CurrencyFromTill])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.WholeNumber]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.WholeNumberFromTill])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.FreeTextCombo]) return true;
    return false;
  }

  static isMappingAllowedForDateFromTill(
    targetType: string
  ) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Date]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.MonthYear]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.FreeTextCombo]) return true;

    return false;
  }

  static isMappingAllowedForMemo() {
    return true;
  }

  static isMappingAllowedForCheck(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Combo]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.List]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Option]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.HierarchicalList])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.MonthYear]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.FreeTextCombo]) return true;

    return false;
  }

  static isMappingAllowedForCurrency(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Numeric]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.NumericFromTill])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.CurrencyFromTill])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.FreeTextCombo]) return true;

    return false;
  }

  static isMappingAllowedForCurrencyFromTill(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Numeric]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.NumericFromTill])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Currency]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.WholeNumber]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.WholeNumberFromTill])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.FreeTextCombo]) return true;

    return false;
  }

  static isMappingAllowedForHierarchicalList(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Combo]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.List]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Option]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.FreeTextCombo]) return true;

    return false;
  }

  static isMappingAllowedForAreaCode() {
    return false;
  }

  static isMappingAllowedForWholeNumber(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Numeric]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.NumericFromTill])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.DateFromTill]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Currency]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.CurrencyFromTill])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.WholeNumberFromTill])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.FreeTextCombo]) return true;
    return false;
  }

  static isMappingAllowedForWholeNumberFromTill(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Numeric]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.NumericFromTill])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.DateFromTill]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.Currency]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.CurrencyFromTill])
      return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.WholeNumber]) return true;
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.FreeTextCombo]) return true;
    return false;
  }

  static isMappingAllowedForMonthYear(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.FreeTextCombo]) return true;
    return false;
  }

  static isMappingAllowedForFraction(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.FreeTextCombo]) return true;
    return false;
  }

  static isMappingAllowedForAreaCodeList(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.FreeTextCombo]) return true;
    return false;
  }

  static isMappingAllowedForFreeTextCombo(targetType: string) {
    if (targetType === FieldDefinitionFieldTypeEnum[FieldDefinitionFieldTypeEnum.FreeTextCombo]) return true;
    return false;
  }
}
