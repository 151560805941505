import { FieldDefinitionFieldTypeEnum } from "./fieldDefinitionFieldTypeEnum";
import { MasterDataListItem } from "./masterDataListItem";
import { MasterDataHierarchicalListItem } from "./masterDataHierarchicalListItem";

export interface FieldDefinition {
    recordKey: string;
    aggregates: string[];
    groupName: string;
    fieldType: FieldDefinitionFieldTypeEnum;
    name: string;
    masterDataList: MasterDataListItem[];
    masterDataHierarchicalList: MasterDataHierarchicalListItem[];
}
