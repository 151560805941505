import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import { HttpRequestOptions } from './http-request-options';
import { LogRecord } from '../models/log-recpord';

@Injectable({
  providedIn: 'root',
})
export class LoggingDataService {
  private migrationMappingUrl: string;  

  constructor(private httpClient: HttpClient,
    environment: EnvironmentService
  ) {
    this.migrationMappingUrl = environment.getConfig().mappingApiUrl ?? ''; 
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async putLog(log: LogRecord, token: string): Promise<any> {
    if (!token || !log) {
      return false;
    }

    const url = `${this.migrationMappingUrl}/support-info`;
    return new Promise((resolve) => {
      this.httpClient
        .put(url, log, this.getHeaderOptions(token, undefined))
        .subscribe({
          next: (value) => resolve(value),
          error: () => resolve(false),
        });
    });
  }

  private getHeaderOptions(token: string, nextPageToken: string | undefined) : HttpRequestOptions {
    const options = {
      headers: { authorization: 'Bearer ' + token },
      params: nextPageToken ? { pagetoken: nextPageToken } : undefined,
    } as HttpRequestOptions;
    return options;
  }
}