import { MigrationDataService } from '../services/migration.data.service';
import { MappingAggregatesEnum } from '../models/api/mappingAggregatesEnum';
import { LoggingDataService} from '../services/logging.data.service';
import { Logger } from './mapping-logger';
import { LogAction } from '../models/log-action';

export class MigrationDataProcess {
  static async RefeedAggregates(
    token: string,
    service: MigrationDataService,
    aggregates: MappingAggregatesEnum[],
    logging: LoggingDataService,
  ): Promise<boolean> {
    try {
      const result = await service.refeedAggregates(aggregates, token);
      if (result === false) return false;
      const aggInfo = aggregates.map(val => MappingAggregatesEnum[val]).join(", ");
      await Logger.log(LogAction.Refeed, token, logging, aggInfo);
    } catch {
      return false;
    }
    return true;
  }
}
