<div class="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 class="font-sans title text-center text-3xl font-bold tracking-tight text-gray-900">Support</h2>
        <p class="font-sans relative text-center text-base leading-8 text-gray-600 sm:max-w-md lg:max-w-none">{{ 'Contact.yourQuestion' | translate }}</p>
        <p class="font-sans relative text-center text-base leading-8 text-gray-600 sm:max-w-md lg:max-w-none">{{ 'Contact.thereForYou' | translate }}</p>        
        <a class="contact-link" href="mailto:premiumsupport@immowelt.de"><p class="font-sans text-center relative text-base leading-8 sm:max-w-md lg:max-w-none">{{ 'Contact.contactUs' | translate }}</p></a>
    </div>
</div>

<footer>
    <div class="mx-auto max-w-7xl overflow-hidden px-6 lg:px-8">
        <nav class="footer-inline -mb-6 columns-2 sm:flex sm:justify-center sm:space-x-12" aria-label="Footer">
            <div *ngFor="let item of links" class="pb-6">
                <a :href="item.href" target="_blank" class="font-sans text-base leading-6">{{ item.name }}</a>
            </div>
        </nav>
    </div>
</footer>
