import { Component } from '@angular/core';
import { Translation } from '@mapping-tool/lib';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mapping-tool-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent {
  
  constructor(
    public translate: TranslateService) {
      this.translate = Translation.initTranslateService(this.translate);
  }
}
