import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CognitoIdToken } from 'amazon-cognito-identity-js';
import { BehaviorSubject } from 'rxjs';
import { AmplifyWrapperService } from './amplify.wrapper.service';
import { AuthServiceInterface } from './auth-service';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements AuthServiceInterface {
  public isAuthenticated = new BehaviorSubject<boolean>(false);
  public newPasswordRequired = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private amplifyWrapperService: AmplifyWrapperService
  ) {
    this.amplifyWrapperService.onSessionRefreshFailure(() => this.logout());
  }

  async checkAuthenticated(): Promise<boolean> {
    return this.amplifyWrapperService
      .currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          this.isAuthenticated.next(true);
          return true;
        }
        this.isAuthenticated.next(false);
        return false;
      })
      .catch(() => {
        this.isAuthenticated.next(false);
        return false;
      });
  }

  async login(username: string, password: string) {
    this.removeStoredTenant();
    return this.amplifyWrapperService
      .signIn(username.toLowerCase(), password)
      .then(async (user) => {
        this.newPasswordRequired.next(false);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.newPasswordRequired.next(true);
        } else {
          this.storeTenant(user);
          this.redirectToMappingPage();
        }

        return user;
      })
      .catch((error) => {
        const response = new Error();
        response.message = error.message;
        response.name = error.name;
        return this.sendErrorResponse(response);
      });
  }

  changeLocation = (href: string) => {
    window.location.href = href;
  };
  
  redirectToMappingPage() {
    this.isAuthenticated.next(true);
    this.changeLocation('/mapping');
  }

  async logout(redirect?: string) {
    this.removeStoredTenant();
    return this.amplifyWrapperService
      .signOut()
      .then(async () => {
        this.isAuthenticated.next(false);
        if (redirect) {
          await this.router.navigate([redirect]);
        } else {
          const elements = document.getElementsByClassName('modal__dialog');
          while (elements.length > 0) {
            elements[0].remove();
          }
          this.changeLocation('/');
        }
        return true;
      })
      .catch((error) => {
        return this.sendErrorResponse(error as Error);
      });
  }

  async getIdToken(): Promise<CognitoIdToken> {
    return this.amplifyWrapperService
      .currentSession()
      .then((session) => {
        return session.getIdToken();
      })
      .catch();
  }

  async refreshIdToken(): Promise<CognitoIdToken> {
    await this.amplifyWrapperService
      .currentAuthenticatedUser()
      .then(async (cognitoUser) => {
        const currentUserSession = cognitoUser?.getSignInUserSession();
        await cognitoUser?.refreshSession(currentUserSession?.refreshToken);
      })
      .catch();

    return this.getIdToken();
  }

  private sendErrorResponse(error: Error): Error {
    this.isAuthenticated.next(false);
    return error;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  storeTenant(user: any) {
    if (!user?.attributes)
      return;
    localStorage.setItem('TENANT', user.attributes['custom:tenantUuid']);
  }

  removeStoredTenant() {
    localStorage.setItem('TENANT', '');
  }
}
