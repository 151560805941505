import { Component, Input, NgZone, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoginService, Translation } from '@mapping-tool/lib';
import { Router } from '@angular/router';

@Component({
  selector: 'mapping-tool-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() title = '';
  isLoggedIn = false;

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private loginService: LoginService,    
    public translate: TranslateService) {
    this.translate = Translation.initTranslateService(this.translate);
  }

  async ngOnInit() {
    this.isLoggedIn = await this.loginService.isValidAuthenticated();
}

  async logout() {
    await this.loginService.logout();
    this.redirectToLoginPage();
  }

  redirectToLoginPage() {
    this.ngZone.run(() => {
      void this.router.navigate(['/']);
    });  
  }
}
