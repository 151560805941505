import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpRequestOptions } from './http-request-options';
import { EnvironmentService } from './environment.service';
import { MappingAggregatesEnum } from '../models/api/mappingAggregatesEnum';

@Injectable({
  providedIn: 'root',
})
export class MigrationDataService {
  private migrationApiUrl: string;

  constructor(private httpClient: HttpClient,
    environment: EnvironmentService
  ) {
    this.migrationApiUrl = environment.getConfig().migrationApiUrl ?? '';
  } 

  async refeedAggregates(aggregates: MappingAggregatesEnum[], token: string): Promise<boolean>{
    if (!token || !aggregates) {
      return false;
    }

    const aggregateNames: string[] = aggregates.map(x=>MappingAggregatesEnum[x]);
    const url = `${this.migrationApiUrl}/migration/refeed-request`;    
    return new Promise((resolve) => {
      this.httpClient
        .post(url, aggregateNames, this.getHeaderOptions(token, undefined))
        .subscribe({
          next: () => resolve(true),
          error: () => resolve(false),
        });
    });
  }  

  private getHeaderOptions(token: string, nextPageToken: string | undefined) : HttpRequestOptions {
    const options = {
      headers: { authorization: 'Bearer ' + token },
      params: nextPageToken ? { pagetoken: nextPageToken } : undefined,
    } as HttpRequestOptions;
    return options;
  }  
}
