import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ChooseTenantErrorEnum, LogAction, LoggingDataService, ManagementCustomerWithToken, SharedParameterService, SupportLoginService, Translation } from '@mapping-tool/lib';
import { Logger } from '@nestjs/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mapping-tool-choose-tenant-page',
  templateUrl: './choose-tenant-page.component.html',
  styleUrls: ['./choose-tenant-page.component.scss'],
})
export class ChooseTenantPageComponent implements OnInit {
  public isPasswordVisible = false;
  form: FormGroup = {} as FormGroup;
  contractIdError = false;
  contractId = 0
  idToken = '';
  showError = false;
  isLoading = false;
  tenantSuccessfullyChosen = false;
  customer: ManagementCustomerWithToken = {} as ManagementCustomerWithToken;
  tenantInfo = '';
  showChooseTenantErrorEnum = ChooseTenantErrorEnum.Undefined;
  loggedInUser = '';

  constructor(private fb: FormBuilder,
    public translate: TranslateService,
    private login: SupportLoginService,
    private ngZone: NgZone,
    private router: Router,
    private parameterService: SharedParameterService,
    private loggingService: LoggingDataService,
    ) {
    this.translate = Translation.initTranslateService(this.translate);
  }

  async ngOnInit() {
    this.idToken = '';
    this.form = this.fb.group({
      contractId: '',
    });
  }

  async onSubmit() {
    this.isLoading = true;
    this.idToken = '';
    this.parameterService.token = '';
    this.tenantSuccessfullyChosen = false;
    this.showError = false;
    this.removeStoredTenant();

    this.contractId = this.form.get('contractId')?.value;
    const contractId: number = + this.contractId

    await this.verifyAccount(contractId);

    this.loggedInUser = localStorage.getItem("USER") ?? '';
    this.isLoading = false;
  }

  async verifyAccount(contractId: number) {
    if (contractId < 100)
      return;

    this.customer = {} as ManagementCustomerWithToken;
    this.showChooseTenantErrorEnum = ChooseTenantErrorEnum.Undefined;

    const result = await this.login.getAuthentication(contractId);

    if (!result) {
      this.showError = true;
      return;
    }

    if (result === ChooseTenantErrorEnum.CustomerNotExist) {
      this.showError = true;
      this.showChooseTenantErrorEnum = ChooseTenantErrorEnum.CustomerNotExist;
      return;
    }

    if (result === ChooseTenantErrorEnum.NoAccessToken || result === ChooseTenantErrorEnum.NoIdToken) {
      this.showChooseTenantErrorEnum = ChooseTenantErrorEnum.NoAccessToken;
      this.showError = true;
      return;
    }

    this.tenantSuccessfullyChosen = true;
    this.customer = result;
    localStorage.setItem('TENANT', this.customer.tenantUuid);
    this.idToken = this.customer.idToken;
    Logger.log(LogAction.Login, this.customer.idToken, this.loggingService);
  }

  removeStoredTenant() {
    localStorage.setItem('TENANT', '');
    this.customer = {} as ManagementCustomerWithToken;
    this.tenantSuccessfullyChosen = false;
  }

  canRemoveTenant() {
    return this.isTenantInStorage();
  }

  isTenantInStorage(): boolean {
    const uuid = localStorage.getItem('TENANT');
    if (!uuid) return false;
    return uuid?.length > 0;
  }

  jumpToMappingPage() {
    this.parameterService.token = this.idToken;
    this.parameterService.contractId = this.contractId;
    this.ngZone.run(() => {
      return void this.router.navigate(['/mapping']);
    });
  }
}
