<button type="button" (click)="login()" class="font-sans text-sm font-normal text-zinc-700 flex items-center justify-center space-x-2 w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6 hover:bg-blue-50 hover:ring-blue-100 px-5 text-center dark:focus:ring-[#4285F4]/55 mr-2 mb-2">
    <svg height="20px" fill="none" class="w-4 ms-4 justify-center items-end right-0" enable-background="new 0 0 48 48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
        <path d="m23 23h-23v-23h23z" fill="#f1511b"/>
        <path d="m48 23h-23v-23h23v23z" fill="#80cc28"/>
        <path d="m23 48h-23v-23h23z" fill="#00adef"/>
        <path d="m48 48h-23v-23h23v23z" fill="#fbbc09"/></svg>
        <span> </span>
        {{ 'Login.signInMicrosoft' | translate }}
  </button>



