import { FieldDefinition } from '../models/api/fieldDefinition';
import { MappableFieldDefinition } from '../models/api/mappableFieldDefinition';
import { UiStates } from '../models/ui-states';
import { MappingUiLogic } from './mapping-ui-logic';

export class AutoPreset {
  static DoPreset(states: UiStates[], mappables: MappableFieldDefinition[], fieldDefinitions: FieldDefinition[]): boolean {
    if (!states || !mappables || !fieldDefinitions ) return false;

    if (!this.autoPresetForSpaces(states, mappables, fieldDefinitions))
      return false;
    return true;
  }

  static autoPresetForSpaces(states: UiStates[], mappables: MappableFieldDefinition[], fieldDefinitions: FieldDefinition[]) {
    mappables.forEach(mbl => {
      if (!mbl.recordKey.startsWith('space') )
        return;

      const state = states.filter(s=>s.recordKey === mbl.recordKey)[0];
      if (!state) return false;

      const spaceId = MappingUiLogic.GetSpaceIdByName(mbl.name, fieldDefinitions);
      if (spaceId.length == 0)
        return;
      state.assignedRecordKey1 = spaceId;
      return true;
    })

    return false;
  }
}
